import React from "react";

const PayHeader = () => {
  return (
    <>
      <header className="pay-header">
        <div className="container">
          <div className="header-inner">
            <div className="logo">
              <a>
                <img src="images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default PayHeader;
