import React, { useState, useEffect, useCallback } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { evmCryptoBalanceCheck } from "../../helper/evmHelper";
import { formatNumber } from "../../helper/helper";
import { Loader } from "../../components/Loader";

const AppsDetail = () => {
  const [appInfo, setAppInfo] = useState("");
  const { appId } = useParams();
  const [tokens, setTokens] = useState([]);
  const [updatedTokens, setUpdatedTokens] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [priceIn, setPriceIn] = useState("USD");
  const [price, setPrice] = useState({
    ETH: 0,
    WBNB: 0,
    BNB: 0,
    USDT: 0,
    USDC: 0,
    AVAX: 0,
    MATIC: 0,
  });
  const currencyList = [
    { name: "USD", value: "USD" },
    { name: "INR", value: "INR" },
    { name: "AUD", value: "AUD" },
  ];

  async function getPrice(token) {
    switch (token.toUpperCase()) {
      case "ETH":
        return price.ETH;
      case "BNB":
      case "WBNB":
        return price.BNB;
      case "USDT":
        return price.USDT;
      case "USDC":
        return price.USDC;
      case "AVAX":
        return price.AVAX;
      case "MATIC":
        return price.MATIC;
      default:
        console.log("Unknown token: " + token);
        return 0;
    }
  }

  const fetchAppDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/apps/getById`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: { appId },
        }
      );
      if (response?.data) {
        setAppInfo(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching app details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [appId]);

  const getCryptoPrice = useCallback(async () => {
    try {
      const currencyValues = currencyList
        .map((currency) => currency.value)
        .join(",");
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=ethereum,matic-network,tether,usd-coin,binancecoin,avalanche-2&vs_currencies=${currencyValues}`
      );
      setPrice({
        ETH: response?.data?.ethereum[priceIn.toLowerCase()],
        BNB: response?.data?.binancecoin[priceIn.toLowerCase()],
        WBNB: response?.data?.binancecoin[priceIn.toLowerCase()],
        USDT: response?.data?.tether[priceIn.toLowerCase()],
        USDC: response?.data?.["usd-coin"][priceIn.toLowerCase()],
        MATIC: response?.data?.["matic-network"][priceIn.toLowerCase()],
        AVAX: response?.data?.["avalanche-2"][priceIn.toLowerCase()],
      });
    } catch (error) {
      console.error("Error fetching crypto price:", error);
    }
  }, [priceIn]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 1000); // 1 second debounce delay

    return () => {
      clearTimeout(handler); // Clear the timeout if searchQuery changes before 1 second
    };
  }, [searchQuery]);

  const fetchTokens = useCallback(async () => {
    setIsLoading(true);
    await getCryptoPrice();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/token/list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 12,
            search: searchQuery,
          },
        }
      );
      const tokens = response?.data?.data;
      setTokens(tokens);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching tokens:", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, debouncedSearchQuery, getCryptoPrice]);

  const updateTokensBalance = useCallback(async () => {
    setIsLoading(true);
    try {
      const updatedTokens = await Promise.all(
        tokens.map(async (item) => {
          try {
            const balance = await evmCryptoBalanceCheck(
              parseInt(item.chainId),
              item?.address,
              appInfo?.EVMWalletMnemonic?.address,
              item?.decimal
            );
            const _token = (
              item?.code.split(".")[0] ?? item?.code
            ).toUpperCase();
            return {
              ...item,
              balance: balance ?? 0,
              usd: (await getPrice(_token)) * balance ?? 0,
            };
          } catch (err) {
            console.error(`Error checking balance for ${item.symbol}:`, err);
            return { ...item, balance: 0 };
          }
        })
      );
      setUpdatedTokens(updatedTokens);
    } catch (e) {
      console.error("Error in evmCryptoBalanceCheck call:", e);
    } finally {
      setIsLoading(false);
    }
  }, [tokens, appInfo]);

  useEffect(() => {
    fetchAppDetails();
  }, [fetchAppDetails]);

  useEffect(() => {
    fetchTokens();
  }, [fetchTokens]);

  useEffect(() => {
    updateTokensBalance();
  }, [tokens, updateTokensBalance]);

  const handlePageChange = (pageNo) => {
    if (pageNo > 0 && pageNo <= totalPages) {
      setCurrentPage(pageNo);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleNavigation = (tokenId) => {
    navigate(`/apps-deposit-receive/${appId}`, { state: { tokenId } });
  };

  const handleSendNavigation = (tokenId) => {
    navigate(`/apps-send-withdraw/${appId}`, { state: { tokenId } });
  };

  return (
    <>
      {isLoading && <Loader />}
      <DashboardHeader appId={appInfo?._id} />
      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <ul>
                    <li className="active">
                      <Link to="/apps">Apps</Link>
                    </li>
                    <li>
                      <a>&rarr; {appInfo?.name} Details</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="white-bg padd20 api-key-payment-box">
                <div className="api-key-payment-left">
                  <h3>{appInfo?.name}</h3>
                  <div className="api-key-btn">
                    <a>
                      <i className="ti ti-key" />
                      <span>API Key</span>
                    </a>
                  </div>
                </div>
                <div className="api-key-payment-right">
                  <a
                    className="btn"
                    onClick={() => {
                      if (appInfo?._id) {
                        navigate(`/apps-payment-links/${appId}`);
                      }
                    }}
                  >
                    <i className="ti ti-arrow-down-left" />
                    <span>Payment Link History</span>
                  </a>
                  <a
                    className="btn"
                    onClick={() => {
                      if (appInfo?._id) {
                        navigate(`/apps-wallet-transactions/${appId}`);
                      }
                    }}
                  >
                    <i className="ti ti-arrow-up-right" />
                    <span>Wallet History</span>
                  </a>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <div className="search-filter-box">
                  <div className="dashborad-search-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Blockchain Network"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                  {/* <div className="filter-box">
                    <select className="form-control">
                      <option>Filter</option>
                    </select>
                  </div> */}
                </div>
                <div className="table-box">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>Coin Name</th>
                        <th>Blockchain Network</th>
                        <th>Code</th>
                        <th>Balance</th>
                        <th>
                          <div className="value-box">
                            <span>Value</span>
                            <div className="usd-select">
                              <select
                                className="form-control"
                                onChange={(event) => {
                                  console.log(
                                    "Selected value is : ",
                                    event.target.value
                                  );
                                  setPriceIn(event.target.value);
                                }}
                              >
                                {currencyList &&
                                  currencyList.map((currency) => (
                                    <option
                                      key={currency.value}
                                      value={currency.value}
                                    >
                                      {currency.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </th>
                        <th>Commands</th>
                      </tr>
                      {updatedTokens.map((val, key) => (
                        <tr key={key}>
                          <td>{val?.symbol}</td>
                          <td>{val?.network}</td>
                          <td>{val?.code}</td>
                          <td>{formatNumber(val?.balance, 7)}</td>
                          <td>{formatNumber(val?.usd, 3)}</td>

                          <td>
                            <div className="option-box">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Options
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <button
                                      onClick={() => handleNavigation(val._id)}
                                      className="dropdown-item"
                                    >
                                      Deposit/Receive
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() =>
                                        handleSendNavigation(val._id)
                                      }
                                      className="dropdown-item"
                                    >
                                      Send/Withdraw
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => {
                                        if (appInfo?._id) {
                                          navigate(
                                            `/apps-payment-links/${appId}`
                                          );
                                        }
                                      }}
                                      className="dropdown-item"
                                    >
                                      Payment Link History
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      // to="/apps-wallet-transactions"
                                      onClick={() => {
                                        if (appInfo?._id) {
                                          navigate(
                                            `/apps-wallet-transactions/${appId}`
                                          );
                                        }
                                      }}
                                      className="dropdown-item"
                                    >
                                      Wallet History
                                    </button>
                                  </li>
                                  {/* <li>
                                    <button
                                      onClick={() => {
                                        if (appInfo?._id) {
                                          navigate(
                                            `/apps-transfer-history/${appId}`
                                          );
                                        }
                                      }}
                                      className="dropdown-item"
                                    >
                                      Transfer History
                                    </button>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-box">
                  <ul>
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                      >
                        <img src="images/left-arrow.png" alt="arrow" />
                      </a>
                    </li>
                    {[...Array(totalPages).keys()].map((pageNo) => (
                      <li
                        key={pageNo + 1}
                        className={currentPage === pageNo + 1 ? "active" : ""}
                      >
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(pageNo + 1);
                          }}
                        >
                          {pageNo + 1}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                      >
                        <img src="images/right-arrow.png" alt="arrow" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppsDetail;
