import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ValidateInputs } from "../helper/helper";
import { Loader } from "../components/Loader";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [wrongEmailMsg, setWrongEmailMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const validate = () => {
    const newErrors = {};

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      setIsLoading(true);
      try {
        const response = await axios
          .post(
            `${process.env.REACT_APP_API_URL}/merchants/verify-user-mail`,
            formData
          )
          .then((response) => {
            console.log("response", response);
            toast.success("Reset Password Link send at your mail id!");

            setFormData({
              email: "",
            });
            setErrors("");
            setWrongEmailMsg("");
          })
          .catch((error) => {
            console.error("error", error);
            setErrors("");
            setWrongEmailMsg(
              "Invalid email address. Please enter a valid email address"
            );
            // toast.error("Invalid email address..Please enter a valid email address11");
          });
      } catch (error) {
        console.log("catch error", error);
        toast.error(
          "Invalid email address.Please enter a valid email address22"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <>
      <Header />
      <div className="site-bg">
        <div className="inner-page-warper section-padding">
          <div className="container">
            <div className="form-box">
              <h2>Forgot Password</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-box-inner white-bg">
                  <div className="form-group">
                    <label>Email</label>
                    <div className="form-group-inner">
                      <div className="fild-icon">
                        <i className="ti ti-mail" />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}

                      <span className="error">{wrongEmailMsg}</span>
                    </div>
                  </div>
                  <div className="form-submit-btn">
                    <button className="btn">Submit</button>
                  </div>
                </div>
              </form>
              {isLoading && <Loader />}
              <div className="form-shape-box1">
                <img src="images/shape1.png" alt="shape" />
              </div>
              <div className="form-shape-box2">
                <img src="images/shape2.png" alt="shape" />
              </div>
              <div className="form-shape-box3">
                <img src="images/shape3.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
