import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

export const ThreeDotLoader = () => {
    return (
        <div>
            <ThreeDots
                visible={true}
                height="35"
                width="35"
                color="#D8A80A"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )
}
