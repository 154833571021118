import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import axios from "axios";
import PaymentLinkTable from "../../components/TransactionsDataTable";
import { formatDate } from "../../helper/helper";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchNotifications = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/notification/get`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
          },
        })
        .then((resp) => {
          console.log("res", resp);
          setNotifications(resp?.data?.notifications);
          setCurrentPage(resp?.data?.currentPage);
          setTotalPages(resp?.data?.totalPages);
        })
        .catch((error) => {
          console.error("Error in notification list", error);
        });
    } catch (error) {
      console.error("Error fetching notification:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [currentPage]);

  const handlePageChange = (pageNo) => {
    if (pageNo > 0 && pageNo <= totalPages) {
      setCurrentPage(pageNo);
    }
  };

  return (
    <>
      <DashboardHeader />

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  {/* <p>Notifications</p> */}
                </div>
                <div className="dashborad-head-right"></div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <div className="table-box">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Notification</th>
                        <th>Time Stamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notifications?.length > 0 ? (
                        notifications.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.message}</td>

                            <td>{formatDate(data?.createdAt)}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                marginTop: "20px",
                              }}
                            >
                              <p>Data not found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-box">
                  <ul>
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                      >
                        <img src="images/left-arrow.png" alt="arrow" />
                      </a>
                    </li>
                    {[...Array(totalPages).keys()].map((pageNo) => (
                      <li
                        key={pageNo + 1}
                        className={currentPage === pageNo + 1 ? "active" : ""}
                      >
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(pageNo + 1);
                          }}
                        >
                          {pageNo + 1}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                      >
                        <img src="images/right-arrow.png" alt="arrow" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
