import React from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link } from "react-router-dom";

const ManageCryptoCurriencies = () => {
  return (
    <>
      <DashboardHeader />

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <ul>
                    <li className="active">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li>
                      <a>&rarr; Manage Cryptocurrencies</a>
                    </li>
                  </ul>
                </div>
                <div className="view-balances-btn">
                  <a className="btn">View All Balances</a>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <div className="table-box">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>Coin</th>
                        <th>Balance</th>
                        <th>BTC Value</th>
                        <th>
                          <div className="usd-select">
                            <select className="form-control">
                              <option>USD</option>
                            </select>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <td>Bitcoin</td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                      <tr>
                        <td>Bitcoin</td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                      <tr>
                        <td>Bitcoin</td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                      <tr>
                        <td>Bitcoin</td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                      <tr>
                        <td>Bitcoin</td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                      <tr>
                        <td>Bitcoin</td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                      <tr>
                        <td>Bitcoin</td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                      <tr>
                        <td>Bitcoin</td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                      <tr>
                        <td>Bitcoin</td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                      <tr>
                        <td>Bitcoin</td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                      <tr className="entire-wallet-box">
                        <td>Est. Value of Entire Wallet</td>
                        <td />
                        <td>
                          100 <i className="ti ti-currency-bitcoin" />
                        </td>
                        <td>100 USD</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageCryptoCurriencies;
