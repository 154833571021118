import React, { useEffect, useState, useRef } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import {
  evmCryptoBalanceCheck,
  evmCryptoTransfer,
  getERC20TransferTxFee,
  getNativeTransferTxFee,
  isSwapPair,
  NATIVE,
  swapCrypto,
} from "../../helper/evmHelper";
import Swal from "sweetalert2";
import { ethers } from "ethers";
import { Loader } from "../../components/Loader";
import { ThreeDotLoader } from "../../components/ThreeDotLoader";
import { getTokenContractAddresses } from "../../helper/contractAddresses";
import {
  generateInvoiceNumber,
  getInvoiceOptions,
} from "../../helper/Invoice.helper";
import html2pdf from "html2pdf.js";
import Invoice from "../../components/Invoice/Invoice";
import { errorSwal, formatDate, successSwal } from "../../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import { setPdfData } from "../../redux/counterSlice";
// import html2pdf from "html2pdf.js";

const SendWithdraw = () => {
  const location = useLocation();
  const { appId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { tokenId } = location.state || {};
  const [tokenInfo, setTokenInfo] = useState(null);
  const [note, setNote] = useState("");
  const [appInfo, setAppInfo] = useState("");
  const [merchantBalance, setMerchantBalance] = useState(0);
  const [merchantEvmNativeBalance, setMerchantEvmNativeBalance] = useState(0);
  const [receiverAddress, setReceiverAddress] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [minimumWithdraw, setMinimumWithdraw] = useState("");
  const [transactionFee, setTransactionFee] = useState(0);
  const [miniLoader, setMiniLoader] = useState(false);
  const intervalRef = useRef(null);
  const [tokensList, setTokensList] = useState([]);
  const [tokenB, setTokenB] = useState("");
  const [isSwapPairFound, setIsSwapPairFound] = useState(false);
  const [swapConvertedAmount, setSwapConvertedAmount] = useState(0);
  const invoiceRef = useRef();
  const [merchantInfo, setMerchantInfo] = useState(null);
  const [adminInfo, setAdminInfo] = useState(null);
  const dispatch = useDispatch();
  const pdfData = useSelector((state) => state.pdfData);

  let timeoutId = null;
  const getTokenAddresses = async () => {
    if (tokenInfo?.chainId) {
      const data = getTokenContractAddresses(tokenInfo?.chainId);
      console.log("data of get token contract addresses  : ", data);
      setTokensList(data);
    }
  };

  useEffect(() => {
    const fetchAppDetails = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/apps/getById`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              appId: appId,
            },
          }
        );

        const response2 = await axios.get(
          `${process.env.REACT_APP_API_URL}/merchants/user-profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response?.data) {
          setAppInfo(response?.data?.data);
        }
        if (response2?.data) {
          setMerchantInfo(response2?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching Mnemonic details:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAppDetails();
  }, [appId]);

  const fetchTokenDetail = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/token/getById`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            tokenId: tokenId,
          },
        }
      );
      if (response?.data) {
        setTokenInfo(response?.data?.data);
        setMinimumWithdraw(response?.data?.data?.minWithdraw);
      }
      return response?.data?.data?.minWithdraw;
    } catch (error) {
      console.error("Error fetching Mnemonic details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTokenDetail();
  }, [tokenId]);

  const getTransactionFee = async (tokenAddress) => {
    try {
      if (
        receiverAddress.length === 42 &&
        tokenAddress[0] === "0" &&
        tokenAddress[1] === "x"
      ) {
        if (tokenAddress === NATIVE) {
          const tx = {
            to: receiverAddress,
            value: ethers.utils.parseEther(withdrawAmount.toString()),
          };
          const value = await getNativeTransferTxFee(tokenInfo?.chainId, tx);
          setTransactionFee(value.slice(0, 8));
        } else {
          const value = await getERC20TransferTxFee(
            tokenInfo?.chainId,
            tokenInfo?.address,
            receiverAddress,
            withdrawAmount,
            tokenInfo?.decimal
          );

          setTransactionFee(value.slice(0, 8));
        }
      } else {
        setTransactionFee(0);
      }
    } catch (e) {
      console.error("tx fee getting error", e);
    }
  };

  const startTransactionFeeInterval = async () => {
    intervalRef.current = setInterval(() => {
      setMiniLoader(true);
      setTimeout(async () => {
        await getTransactionFee(tokenInfo?.address);
        setMiniLoader(false);
      }, 2000);
    }, 18000);
    getTransactionFee(tokenInfo?.address);
  };

  const clearTransactionFeeInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (receiverAddress !== "" && withdrawAmount !== "") {
      startTransactionFeeInterval();
    }
    return () => clearTransactionFeeInterval();
  }, [receiverAddress, withdrawAmount, tokenInfo?.address]);

  async function getCryptoBalance() {
    setIsLoading(true);
    if (!(tokenInfo && appInfo)) {
      return 0;
    }
    const balance = await evmCryptoBalanceCheck(
      tokenInfo?.chainId,
      tokenInfo?.address,
      appInfo?.EVMWalletMnemonic?.address,
      tokenInfo?.decimal
    );
    const nativeBalance = await evmCryptoBalanceCheck(
      tokenInfo?.chainId,
      NATIVE,
      appInfo?.EVMWalletMnemonic?.address,
      tokenInfo?.decimal
    );
    setMerchantEvmNativeBalance(nativeBalance);
    setMerchantBalance(balance);
    setIsLoading(false);
    return balance;
  }

  useEffect(() => {
    try {
      getCryptoBalance();
      getTokenAddresses();
    } catch (error) {
      console.log("Error : ", error);
    }
  }, [tokenInfo, appInfo]);

  const fetchFlatformFee = async () => {
    let data = null;
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-platform-fee`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response?.data) {
        data = response?.data?.data;
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch platform fee",
        });
      }
    } catch (error) {
      console.error("Error fetching fetchFlatformFee :", error);
    } finally {
      setIsLoading(false);
      return data;
    }
  };

  const HTML2PDFConversionCall = async (adminFee, privateKey) => {
    let data = false;
    try {
      let receipt = null;
      let receipt2 = null;

      const adminCharges = (withdrawAmount * adminFee?.platformFee) / 100;
      const updatedWithdrawAmount = withdrawAmount - adminCharges;

      if (isSwapPairFound) {
        alert("Token transfering after swap");
        let path = [tokenInfo?.address, tokenB];
        receipt = await swapCrypto(
          tokenInfo?.chainId,
          privateKey,
          path,
          updatedWithdrawAmount,
          tokenInfo?.decimal
        );

        receipt2 = await evmCryptoTransfer(
          tokenInfo?.chainId,
          privateKey,
          tokenInfo?.address,
          adminFee?.adminWallet,
          adminCharges
        );
      } else {
        alert("Token transfering without swap");

        receipt = await evmCryptoTransfer(
          tokenInfo?.chainId,
          privateKey,
          tokenInfo?.address,
          receiverAddress,
          updatedWithdrawAmount
        );

        receipt2 = await evmCryptoTransfer(
          tokenInfo?.chainId,
          privateKey,
          tokenInfo?.address,
          adminFee?.adminWallet,
          adminCharges
        );
      }

      // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      const invoiceNumber = await generateInvoiceNumber();
      const inputsToDPF = {
        app_id: appInfo?._id,
        app_name: appInfo?.name,
        merchant_name: merchantInfo?.name,
        merchant_id: merchantInfo?._id,
        receiver_address: receiverAddress,
        sender_address: appInfo?.EVMWalletMnemonic?.address,
        invoice_no: invoiceNumber,
        date: formatDate(Date.now()),
        email: merchantInfo?.email,
        token_name: tokenInfo?.symbol,
        chainId: tokenInfo?.chainId,
        value: withdrawAmount,
        hash: await receipt?.transactionHash.toString(),
        platform_fee: adminFee?.platformFee,
      };

      dispatch(setPdfData(inputsToDPF));

      const invoiceElement = invoiceRef.current;
      const invoiceOptions = await getInvoiceOptions();

      const pdfConversion = await html2pdf()
        .from(invoiceElement)
        .set(invoiceOptions)
        .outputPdf("blob");

      if (!pdfConversion || !merchantInfo?.email) {
        console.error("PDF blob or email is missing.");
        return;
      }
      let gas = Number(await receipt?.gasUsed);
      let gasPrice = Number(await receipt?.effectiveGasPrice);

      const formData = new FormData();
      formData.append("file", pdfConversion, "Invoice.pdf");
      formData.append("email", merchantInfo?.email);
      formData.append("appsId", appInfo._id);
      formData.append("blockNumber", (await receipt?.blockNumber) ?? 0);
      formData.append("amount", updatedWithdrawAmount);
      formData.append("toAddress", receiverAddress);
      formData.append("fromAddress", appInfo?.EVMWalletMnemonic?.address);
      formData.append("gas", gas ?? 0);
      formData.append("gasPrice", gasPrice ?? 0);
      formData.append("hash", await receipt?.transactionHash.toString());
      formData.append("symbol", tokenInfo?.symbol);
      formData.append("chainId", tokenInfo?.chainId);
      formData.append("invoice", invoiceNumber);

      // ________________________________________________________________

      if (receipt && receipt2) {
        console.log("tx receipt : ", receipt);
        const url = `${process.env.REACT_APP_API_URL}/merchant-app-tx/add`;

        const res = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log("resresresresresresres : ", res);

        if (res?.data.status) {
          data = true;

          successSwal(
            "Transaction succesfull",
            "Transaction invoice sent to your registerd email address"
          );
        } else {
          errorSwal("Database error: Unable to add transaction");
        }
      } else {
        errorSwal("Transaction failed");
      }
    } catch (error) {
      console.log("Error in HTML2PDFConversionCall : ", error);
    }
    return data;
  };

  const submitTransaction = async function () {
    // minimumWithdraw
    // const minWithdrawAmount = await fetchTokenDetail();
    if (minimumWithdraw > withdrawAmount) {
      errorSwal(
        "Invalid Inputs",
        `Minimum withdrawal amount is ${minimumWithdraw} ${tokenInfo?.symbol}`
      );
      return;
    }

    if (merchantEvmNativeBalance < 0.001) {
      errorSwal(
        "Validation Failed",
        `Insufficient ${tokenInfo?.network} native balance for transaction`
      );
      return;
    }

    if (!ethers.utils.isAddress(receiverAddress)) {
      errorSwal("Validation Failed", `Invalid receiver wallet address`);
      return;
    }

    const adminFee = await fetchFlatformFee();
    setAdminInfo(adminFee);
    console.log("adminFee : ", adminFee);

    if (!adminFee) {
      return;
    }
    console.log("appInfo : ", appInfo);

    Swal.fire({
      title: "Please provide your password to verify your account",
      input: "password",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off", // Fixed autocompletion
      },
      confirmButtonText: "Confirm",
      showLoaderOnConfirm: true,
      preConfirm: async (password) => {
        clearTransactionFeeInterval();
        setIsLoading(true);
        try {
          const url = `${process.env.REACT_APP_API_URL}/merchants/check-password`;
          const payload = {
            password: password,
            appId: appInfo?._id,
          };

          await axios
            .post(url, payload, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then(async (response) => {
              const privateKey = response?.data?.data?.privateKey;
              await HTML2PDFConversionCall(adminFee, privateKey);
            })
            .catch((error) => {
              console.log("error : ", error);

              Swal.showValidationMessage(
                `Error: ${
                  Array.isArray(error.response.data.message) &&
                  error.response.data.message.length > 0
                    ? error.response.data.message[0]
                    : error.response.data.message
                }`
              );
            });
        } catch (error) {
          Swal.showValidationMessage(`
              Request failed: ${error}
            `);
        } finally {
          setIsLoading(false);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire({
        //   title: `Transaction succesfull`,
        //   imageUrl: result.value.avatar_url,
        // });
        successSwal(
          "Transaction succesfull",
          "Transaction invoice sent to your registerd email address"
        );
      }
    });
  };

  const convertSwapAmount = async (amountIn, tokenB) => {
    if (tokenB === null || tokenB === "") {
      setIsSwapPairFound(false);
      setSwapConvertedAmount("");
      return;
    }

    setIsLoading(true);
    try {
      const amountsInWei = await ethers.utils.parseUnits(
        amountIn,
        tokenInfo?.decimal
      );
      console.log("Amount in wei : ", Number(amountsInWei));

      const swapPair = await isSwapPair(
        tokenInfo?.chainId,
        tokenInfo?.address,
        tokenB,
        Number(amountsInWei),
        tokenInfo?.decimal
      );

      setIsSwapPairFound(swapPair.result);

      console.log("AmountOut is : ", swapPair.amountOut);

      setSwapConvertedAmount(swapPair.amountOut);
    } catch (error) {
      console.log("Error in convertSwapAmount : ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputAmountInChange = (event) => {
    const amountIn = event.target.value;

    if (parseFloat(amountIn) <= parseFloat(merchantBalance)) {
      setWithdrawAmount(amountIn);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (amountIn > 0) {
        timeoutId = setTimeout(() => {
          convertSwapAmount(amountIn, tokenB);
        }, 1000);
      }
    } else {
      setWithdrawAmount("");
      setSwapConvertedAmount("");
    }
  };

  return (
    <>
      <DashboardHeader />

      {isLoading && <Loader />}

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <ul>
                    <li className="active">
                      <Link to="/apps">Apps &rarr; </Link>
                    </li>
                    <li className="active">
                      <Link to={`/apps-detail/${appId}`}>{appInfo?.name}</Link>
                    </li>
                    <li>
                      <a>&rarr; Send/Withdraw</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <div className="send-withdraw-list">
                  <ul>
                    <li>
                      <div className="send-withdraw-left">
                        Transaction Balance
                      </div>
                      <div className="send-withdraw-right">
                        {String(merchantEvmNativeBalance).slice(0, 11)}{" "}
                        {tokenInfo?.code.split(".")[1] || tokenInfo?.code}
                      </div>
                    </li>

                    <li>
                      <div className="send-withdraw-left">
                        Minimum Withdrawal
                      </div>
                      <div className="send-withdraw-right">
                        {String(minimumWithdraw).slice(0, 11)}{" "}
                        {tokenInfo?.symbol}
                      </div>
                    </li>

                    <li>
                      <div className="send-withdraw-left">Current Balance</div>
                      <div className="send-withdraw-right">
                        {String(merchantBalance).slice(0, 11)}{" "}
                        {tokenInfo?.symbol}
                      </div>
                    </li>
                    <li>
                      <div className="send-withdraw-left">
                        Send / Withdrawal Amount
                      </div>
                      <div className="send-withdraw-right">
                        <div className="send-withdraw-amount">
                          <div className="withdraw-amount-fild">
                            <input
                              type="number"
                              name="withdraw-amount"
                              value={withdrawAmount}
                              className="form-control"
                              placeholder="Enter Amount"
                              onChange={handleInputAmountInChange}
                            />
                          </div>
                          <div
                            className="form-control"
                            style={{
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "max",
                            }}
                          >
                            {tokenInfo?.symbol}
                          </div>

                          <div
                            className="usd-btc-box"
                            style={{
                              height: "max-content",
                            }}
                          >
                            <i
                              className="ti ti-arrows-exchange"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </div>

                          {isSwapPairFound && (
                            <div className="withdraw-amount-fild">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Amount"
                                value={swapConvertedAmount}
                                disabled={true}
                              />
                            </div>
                          )}

                          <div className="withdraw-amount-fild">
                            <select
                              className="form-control"
                              onChange={async (event) => {
                                let _tokenB = event.target.value;
                                console.log("Dropdown token is : ", _tokenB);
                                setTokenB(_tokenB);

                                // If both are same token then don't need to convert swap amount
                                if (
                                  _tokenB.toLowerCase() ===
                                  tokenInfo?.address.toLowerCase()
                                ) {
                                  setIsSwapPairFound(false);
                                  return;
                                } else {
                                  await convertSwapAmount(
                                    withdrawAmount,
                                    _tokenB
                                  );
                                }
                              }}
                            >
                              {tokensList &&
                                tokensList.map((token, key) => (
                                  <option key={key} value={token.value}>
                                    {token.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="send-withdraw-left">Transaction Fee</div>
                      {miniLoader ? (
                        <ThreeDotLoader />
                      ) : (
                        <div className="send-withdraw-right">
                          {receiverAddress === "" || withdrawAmount === ""
                            ? 0
                            : transactionFee}
                          <span style={{ marginLeft: "4px" }}>
                            {tokenInfo?.code.split(".")[1] || tokenInfo?.code}
                          </span>
                        </div>
                      )}
                    </li>

                    <li>
                      <div className="send-withdraw-left">Total Withdrawal</div>
                      <div
                        className="send-withdraw-right"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {withdrawAmount || 0} {tokenInfo?.symbol} +{" "}
                        {miniLoader ? (
                          <ThreeDotLoader />
                        ) : (
                          <>
                            {receiverAddress === "" || withdrawAmount === ""
                              ? 0
                              : transactionFee}
                            <span style={{ marginLeft: "4px" }}>
                              {tokenInfo?.code.split(".")[1] || tokenInfo?.code}
                            </span>
                          </>
                        )}
                      </div>
                    </li>

                    <li>
                      <div className="send-withdraw-left">Send To</div>
                      <div className="send-withdraw-right">
                        <div className="address-note-box">
                          <input
                            type="text"
                            value={receiverAddress}
                            className="form-control"
                            placeholder="Enter Address"
                            onChange={(event) => {
                              setReceiverAddress(event.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="send-withdraw-left">Optional Note</div>
                      <div className="send-withdraw-right">
                        <div className="address-note-box">
                          <input
                            type="text"
                            value={note}
                            className="form-control"
                            placeholder="Enter Note"
                            onChange={(event) => {
                              setNote(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="request-withdrawal-btn">
                  <button className="btn" onClick={submitTransaction}>
                    Request Withdrawal/Send
                  </button>
                  <div id="invoice" style={{ display: "none" }}>
                    {<Invoice ref={invoiceRef} details={pdfData} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendWithdraw;
