import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import Swal from "sweetalert2";

const Apps = () => {
  const navigate = useNavigate();

  //save app
  const [existAppNameMsg, setAppNameMsg] = useState("");
  const [appName, setAppName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});

  //show app list
  const [apps, setApps] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");

  //show public and private key
  const [publicKey, setPublicKey] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  //updating name and description
  const [editAppId, setEditAppId] = useState("");
  const [editAppName, setEditAppName] = useState("");
  const [editDescription, setEditDescription] = useState("");

  //show app list

  const fetchApps = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/apps/get`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
            search: searchQuery,
          },
        })
        .then((resp) => {
          console.log("res", resp);
          setApps(resp?.data?.data);
          setCurrentPage(resp?.data?.currentPage);
          setTotalPages(resp?.data?.totalPages);
        })
        .catch((error) => {
          console.error("error in apss list", error);
        });
    } catch (error) {
      console.error("Error fetching apps:", error);
    }
  };

  useEffect(() => {
    fetchApps(currentPage);
  }, [currentPage, searchQuery]);

  //save app
  const handleCreateApp = async () => {
    const newErrors = {};
    if (!appName.trim()) newErrors.appName = "App Name is required";
    if (!description.trim()) newErrors.description = "Description is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setAppNameMsg("");

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_URL}/apps/add`,
          {
            name: appName,
            description: description,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((resp) => {
          console.log("App created successfully:", resp.data);
          toast.success("Apps created successfully!");

          setAppName("");
          setDescription("");
          setAppNameMsg("");

          fetchApps(1);
        })
        .catch((error) => {
          console.error("appp error", error);
          // setAppNameMsg("This app name already exists");
          setAppNameMsg(error.response.data.message);
          console.error("Error creating app11:", error);
        });
    } catch (error) {
      console.error("Error creating app22:", error);
      setAppName("");
    }
  };

  const handlePageChange = (pageNo) => {
    if (pageNo > 0 && pageNo <= totalPages) {
      setCurrentPage(pageNo);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  //showing public and private keys
  const fetchApiKeys = async (appId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/apps/keys`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            appId: appId,
          },
        }
      );

      if (response?.data) {
        setPublicKey(response?.data?.publicKey);
        setPrivateKey(response?.data?.privateKey);
      }
    } catch (error) {
      console.error("Error fetching API keys:", error);
    }
  };

  const handleApiKeyClick = (appId) => {
    fetchApiKeys(appId);
  };

  //copy public private keys
  const handleCopyClick = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  //fetch app details for editing
  const fetchAppDetails = async (appId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/apps/getById`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            appId: appId,
          },
        }
      );

      if (response?.data) {
        setEditAppId(appId);
        setEditAppName(response?.data?.data?.name);
        setEditDescription(response?.data?.data?.description);
      }
    } catch (error) {
      console.error("Error fetching app details:", error);
    }
  };

  const handleEditAppClick = (appId) => {
    fetchAppDetails(appId);
  };

  //update app
  const handleUpdateApp = async () => {
    const newErrors = {};
    if (!editAppName.trim()) newErrors.appName = "App Name is required";
    if (!editDescription.trim())
      newErrors.description = "Description is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setAppNameMsg("");

    try {
      const response = await axios
        .put(
          `${process.env.REACT_APP_API_URL}/apps/update`,
          {
            name: editAppName,
            description: editDescription,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              appId: editAppId,
            },
          }
        )
        .then((resp) => {
          console.log("App updated successfully:", resp?.data);
          toast.success("App updated successfully!");
          fetchApps(1); // Fetch the first page of apps
        })
        .catch((error) => {
          setAppNameMsg("This app name already exists");
          console.error("Error updating app111:", error);
        });
    } catch (error) {
      console.error("Error updating app222:", error);
    }
  };

  //Delete App
  const handleDeleteApp = async (event, appId) => {
    event.preventDefault(); // Prevent the default action
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete the app?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios
            .delete(`${process.env.REACT_APP_API_URL}/apps/delete`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              params: {
                appId: appId,
              },
            })
            .then((response) => {
              console.log("App deleted successfully:", response?.data);
              toast.success("App deleted successfully!");
              fetchApps(1);
              // Refresh the list of apps
              setApps(apps.filter((app) => app._id !== appId));
            })
            .catch((error) => {
              console.error("Error resetting password:", error);
              toast.error("Old password not matched.");
            });
        } catch (error) {
          console.error("Error deleting app:", error);
          toast.error("Failed to delete app.");
        }
      }
    });
  };

  // console.log('showCreateModal',showCreateModal)

  return (
    <>
      <DashboardHeader />
      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="dashborad-search-box">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="view-balances-btn">
                  <a
                    href="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    // onClick={() => setShowCreateModal(true)} // Open modal using state
                  >
                    Create New App
                  </a>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <div className="table-box">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>App Id</th>
                        <th>App Name</th>
                        <th>Created On</th>
                        <th>Action</th>
                      </tr>

                      {apps?.length > 0 ? (
                        apps.map((app) => (
                          <tr key={app?._id}>
                            <td>
                              <Link to={`/apps-detail/${app?._id}`}>
                                {app?._id}
                              </Link>
                            </td>

                            <td>
                              <Link to={`/apps-detail/${app?._id}`}>
                                {app?.name}
                              </Link>
                            </td>

                            <td>
                              <Link to={`/apps-detail/${app?._id}`}>
                                {moment(app?.createdAt).format("DD MMMM YYYY")}
                              </Link>
                            </td>
                            <td>
                              <Link to={`/apps-detail/${app._id}`}>
                                <div className="table-action-btn api-key-btn">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal2"
                                    onClick={() => handleApiKeyClick(app?._id)}
                                  >
                                    <i className="ti ti-key" />
                                    <span>API Key</span>
                                  </a>
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal1"
                                    onClick={() => handleEditAppClick(app?._id)}
                                  >
                                    <i className="ti ti-edit" />
                                    <span>Edit App</span>
                                  </a>
                                  {/* <a
                                    href="#"
                                    onClick={(event) =>
                                      handleDeleteApp(event, app?._id)
                                    }
                                  >
                                    <i className="ti ti-trash" />
                                    <span>Delete App</span>
                                  </a> */}
                                </div>
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                marginTop: "20px",
                              }}
                            >
                              <p>Data not found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-box">
                  <ul>
                    <li>
                      <a
                        href="#"
                        // onClick={() => handlePageChange(currentPage - 1)}
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                      >
                        <img src="images/left-arrow.png" alt="arrow" />
                      </a>
                    </li>
                    {[...Array(totalPages).keys()].map((pageNo) => (
                      <li
                        key={pageNo + 1}
                        className={currentPage === pageNo + 1 ? "active" : ""}
                      >
                        <a
                          href="#"
                          // onClick={() => handlePageChange(pageNo + 1)}
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(pageNo + 1);
                          }}
                        >
                          {pageNo + 1}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a
                        href="#"
                        // onClick={() => handlePageChange(currentPage + 1)}
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                      >
                        <img src="images/right-arrow.png" alt="arrow" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Create new App */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Create new App
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setErrors({});
                  setAppNameMsg("");
                  setAppName("");
                  setDescription("");
                }}
              />
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>Enter Name</label>
                <div className="form-group-inner">
                  <div className="fild-icon">
                    <i className="ti ti-keyframe" />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    value={appName}
                    onChange={(e) => setAppName(e.target.value)}
                  />
                  {errors.appName && (
                    <div className="error">{errors.appName}</div>
                  )}
                  <span className="error">{existAppNameMsg}</span>
                </div>
              </div>
              <div className="form-group">
                <label>Description</label>
                <div className="form-group-inner">
                  <div className="fild-icon">
                    <i className="ti ti-info-circle" />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {errors.description && (
                    <div className="error">{errors.description}</div>
                  )}
                </div>
              </div>
              <div className="form-submit-btn">
                <button className="btn" onClick={handleCreateApp}>
                  Create App
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit App */}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel1">
                Edit App
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setErrors({});
                  setAppNameMsg("");
                }}
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Enter Name</label>
                  <div className="form-group-inner">
                    <div className="fild-icon">
                      <i className="ti ti-keyframe" />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={editAppName}
                      onChange={(e) => setEditAppName(e.target.value)}
                    />
                    {errors.appName && (
                      <p className="error">{errors.appName}</p>
                    )}
                    {existAppNameMsg && (
                      <p className="error">{existAppNameMsg}</p>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label>Description</label>
                  <div className="form-group-inner">
                    <div className="fild-icon">
                      <i className="ti ti-info-circle" />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      value={editDescription}
                      onChange={(e) => setEditDescription(e.target.value)}
                    />
                    {errors.description && (
                      <p className="error">{errors.description}</p>
                    )}
                  </div>
                </div>
              </form>
              <div className="form-submit-btn">
                <button className="btn" onClick={handleUpdateApp}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* API Key */}
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel1">
                API Key
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body api-key-modal">
              <div className="form-group app-id-box">
                <label>Public Key</label>
                <div className="form-group-inner">
                  <input
                    type="text"
                    className="form-control"
                    value={publicKey}
                    readOnly
                  />
                  <button
                    className="copy-code-btn"
                    onClick={() => handleCopyClick(publicKey)}
                  >
                    <i className="ti ti-copy" />
                  </button>
                </div>
              </div>
              <div className="form-group app-id-box">
                <label>Private Key</label>
                <div className="form-group-inner">
                  <input
                    type="text"
                    className="form-control"
                    value={privateKey}
                    readOnly
                  />
                  <button
                    className="copy-code-btn"
                    onClick={() => handleCopyClick(privateKey)}
                  >
                    <i className="ti ti-copy" />
                  </button>
                </div>
              </div>
              {/* <div className="form-group app-id-box">
                <label>HTTPS</label>
                <div className="form-group-inner">
                  <input type="text" className="form-control" placeholder />
                  <button className="copy-code-btn">
                    <i className="ti ti-copy" />
                  </button>
                </div>
              </div> */}
              {/* <div className="form-submit-btn">
                <button className="btn">Save</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Apps;
