import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    document.querySelector("body,html").scrollTop = 0;
    const userIsLoggedIn = localStorage.getItem("token");
    setIsLoggedIn(userIsLoggedIn);
  }, [location]);

  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === "/";
    } else {
      return location.pathname.startsWith(path);
    }
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="header-inner">
            <div className="logo">
              <Link to="/">
                <img src="images/logo.png" alt="logo" />
              </Link>
            </div>
            <nav className="navbar navbar-expand-lg">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      to="/about-us"
                      // className="nav-link active"
                      className={`nav-link ${
                        isActive("/about-us") ? "activee" : ""
                      }`}
                      aria-current="page"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/our-services"
                      className={`nav-link ${
                        isActive("/our-services") ? "activee" : ""
                      }`}
                    >
                      Our Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/how-it-works"
                      className={`nav-link ${
                        isActive("/how-it-works") ? "activee" : ""
                      }`}
                    >
                      How it works
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/pricing"
                      className={`nav-link ${
                        isActive("/pricing") ? "activee" : ""
                      }`}
                    >
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/apis"
                      className={`nav-link ${
                        isActive("/apis") ? "activee" : ""
                      }`}
                    >
                      APIs
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="inquire-login-btn">
              {!isLoggedIn && (
                <Link to="/inquire-now" className="btn inquire-btn">
                  Inquire Now
                </Link>
              )}

              {isLoggedIn ? (
                <Link to="/dashboard" className="btn login-btn">
                  Dashboard
                </Link>
              ) : (
                <Link to="/login" className="btn login-btn">
                  Log In
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
