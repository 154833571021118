import React from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link } from "react-router-dom";

const ConversionHistory = () => {
  return (
    <>
      <DashboardHeader />
      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <ul>
                    <li className="active">
                      <Link to="/apps">Apps &rarr; </Link>
                    </li>
                    <li className="active">
                      <Link to="/apps-detail">ABC App Details</Link>
                    </li>
                    <li>
                      <a>&rarr; Conversion History</a>
                    </li>
                  </ul>
                </div>
                <div className="dashborad-head-right">
                  <div className="year-select-box">
                    <select className="form-control">
                      <option>July 2023</option>
                    </select>
                  </div>
                  <div className="view-balances-btn">
                    <a className="btn">Export Month</a>
                    <a className="btn">Export All</a>
                  </div>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <div className="table-box">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>ID</th>
                        <th>Time Requested</th>
                        <th>Status</th>
                        <th>Amount Sent</th>
                        <th>Exchange Rate</th>
                        <th>Amount Received</th>
                      </tr>
                      <tr>
                        <td>12234656</td>
                        <td>10:30 pm</td>
                        <td>
                          <button className="status success">Success</button>
                        </td>
                        <td>200 USD</td>
                        <td>10</td>
                        <td>200 USD</td>
                      </tr>
                      <tr>
                        <td>12234656</td>
                        <td>10:30 pm</td>
                        <td>
                          <button className="status pending">Pending</button>
                        </td>
                        <td>200 USD</td>
                        <td>10</td>
                        <td>200 USD</td>
                      </tr>
                      <tr>
                        <td>12234656</td>
                        <td>10:30 pm</td>
                        <td>
                          <button className="status success">Success</button>
                        </td>
                        <td>200 USD</td>
                        <td>10</td>
                        <td>200 USD</td>
                      </tr>
                      <tr>
                        <td>12234656</td>
                        <td>10:30 pm</td>
                        <td>
                          <button className="status pending">Pending</button>
                        </td>
                        <td>200 USD</td>
                        <td>10</td>
                        <td>200 USD</td>
                      </tr>
                      <tr>
                        <td>12234656</td>
                        <td>10:30 pm</td>
                        <td>
                          <button className="status success">Success</button>
                        </td>
                        <td>200 USD</td>
                        <td>10</td>
                        <td>200 USD</td>
                      </tr>
                      <tr>
                        <td>12234656</td>
                        <td>10:30 pm</td>
                        <td>
                          <button className="status pending">Pending</button>
                        </td>
                        <td>200 USD</td>
                        <td>10</td>
                        <td>200 USD</td>
                      </tr>
                      <tr>
                        <td>12234656</td>
                        <td>10:30 pm</td>
                        <td>
                          <button className="status success">Success</button>
                        </td>
                        <td>200 USD</td>
                        <td>10</td>
                        <td>200 USD</td>
                      </tr>
                      <tr>
                        <td>12234656</td>
                        <td>10:30 pm</td>
                        <td>
                          <button className="status pending">Pending</button>
                        </td>
                        <td>200 USD</td>
                        <td>10</td>
                        <td>200 USD</td>
                      </tr>
                      <tr>
                        <td>12234656</td>
                        <td>10:30 pm</td>
                        <td>
                          <button className="status success">Success</button>
                        </td>
                        <td>200 USD</td>
                        <td>10</td>
                        <td>200 USD</td>
                      </tr>
                      <tr>
                        <td>12234656</td>
                        <td>10:30 pm</td>
                        <td>
                          <button className="status pending">Pending</button>
                        </td>
                        <td>200 USD</td>
                        <td>10</td>
                        <td>200 USD</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConversionHistory;
