import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import PaymentLinkTable from "../../components/TransactionsDataTable";
import { Loader } from "../../components/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import datepicker styles
import { exportToPDF } from "../../helper/helper";
import { FaTimes } from "react-icons/fa"; // Import a clear icon

const WalletTransactions = () => {
  const { appId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [appInfo, setAppInfo] = useState(null);
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null); // State for end date

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchAppDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/apps/getById`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              appId: appId,
            },
          }
        );
        console.log("response response", response);
        if (response?.data) {
          setAppInfo(response?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching Mnemonic details:", error);
      }
    };
    fetchAppDetails();
  }, [appId]);

  async function getWithdrawHistory() {
    try {
      setIsLoading(true);
      // Prepare query parameters
      const params = {
        pageNo: currentPage,
        limitVal: 15,
      };
      // Add date range filter if both startDate and endDate are selected
      if (startDate && endDate) {
        params.startDate = startDate;
        params.endDate = endDate;
      }

      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant-app-tx/merchant-tx-list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: params,
        }
      );
      setWithdrawHistory(res?.data);
      setCurrentPage(res?.data?.currentPage);
      setTotalPages(res?.data?.totalPages);
    } catch (err) {
      console.log("Error getting wallet history: ", err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getWithdrawHistory(currentPage);
  }, [appInfo, currentPage]);

  // Handle date input change
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  // Handle Search button click
  const handleSearchClick = () => {
    getWithdrawHistory(); // Fetch data with selected date range
  };

  // Clear selected dates
  // Clear selected dates and fetch all data
  const clearStartDate = () => {
    setStartDate(null);
    getWithdrawHistory(); // Fetch all data after clearing date
  };

  const clearEndDate = () => {
    setEndDate(null);
    getWithdrawHistory(); // Fetch all data after clearing date
  };

  //pagination
  const handlePageChange = (pageNo) => {
    if (pageNo > 0 && pageNo <= totalPages) {
      setCurrentPage(pageNo);
    }
  };

  // const handledownload = () => {
  //   console.log("withdraw history", withdrawHistory)
  // }

  console.log("appInfo : ", appInfo);
  console.log("appInfo : ", appInfo);

  return (
    <>
      {isLoading && <Loader />}
      <DashboardHeader appId={appInfo?._id} />
      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <ul>
                    <li className="active">
                      <Link to="/apps">Apps &rarr; </Link>
                    </li>
                    <li className="active">
                      <Link to={`/apps-detail/${appId}`}>{appInfo?.name}</Link>
                    </li>
                    <li>
                      <a>&rarr; Wallet Transaction</a>
                    </li>
                  </ul>
                </div>
                <div className="dashborad-head-right">
                  <div className="date-range-select d-flex gap-2">
                    <div className="position-relative">
                      <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Start Date"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={new Date()}
                      />
                      {startDate && (
                        <FaTimes
                          className="clear-icon"
                          onClick={clearStartDate}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "#888",
                          }}
                        />
                      )}
                    </div>
                    <div className="position-relative">
                      <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="End Date"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={new Date()}
                      />
                      {endDate && (
                        <FaTimes
                          className="clear-icon"
                          onClick={clearEndDate}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "#888",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="view-balances-btn">
                    <a className="btn" onClick={handleSearchClick}>
                      Search
                    </a>
                    {/* Added onClick handler */}
                    {/* <a className="btn">Export All</a> */}

                    <a
                      className="btn"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#f8f9fa",
                      }}
                      onClick={() => {
                        exportToPDF(withdrawHistory?.data);
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = "#D3D3D3")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "#f8f9fa")
                      }
                    >
                      Export All
                    </a>
                  </div>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <PaymentLinkTable
                  transactions={withdrawHistory?.data}
                  isReceivedAmount={true}
                />

                <div className="pagination-box">
                  <ul>
                    <li>
                      <a
                        href="#"
                        // onClick={() => handlePageChange(currentPage - 1)}
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                      >
                        <img src="images/left-arrow.png" alt="arrow" />
                      </a>
                    </li>
                    {[...Array(totalPages).keys()].map((pageNo) => (
                      <li
                        key={pageNo + 1}
                        className={currentPage === pageNo + 1 ? "active" : ""}
                      >
                        <a
                          href="#"
                          // onClick={() => handlePageChange(pageNo + 1)}
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(pageNo + 1);
                          }}
                        >
                          {pageNo + 1}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a
                        href="#"
                        // onClick={() => handlePageChange(currentPage + 1)}
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                      >
                        <img src="images/right-arrow.png" alt="arrow" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletTransactions;
