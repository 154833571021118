import React, { useState } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import { Loader } from "../../components/Loader";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState({});

  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validateForm = () => {
    const errors = {};
    if (!currentPassword) {
      errors.currentPassword = "Old Password cannot be empty.";
    } else if (!newPassword) {
      errors.newPassword = "New Password cannot be empty.";
    } else if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password cannot be empty.";
    } else if (newPassword.length < 6) {
      errors.newPassword = "Password must be at least 6 characters long.";
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+|}{":?><,./;'[\]\\`~]).{6,}$/.test(
        newPassword
      )
    ) {
      errors.newPassword =
        "Password must contain at least one uppercase letter, one lowercase letter, one special character and one number.";
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword =
        "Confirm Password does not match with new Password.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to reset the password?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          await axios
            .put(
              `${process.env.REACT_APP_API_URL}/merchants/change-user-password`,
              {
                currentPassword: currentPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((response) => {
              setIsLoading(false);
              toast.success("Password changed successfully!");
              localStorage.removeItem("token");
              navigate("/login");
            })
            .catch((error) => {
              setIsLoading(false);
              console.error("Error resetting password:", error);
              toast.error("Old password not matched.");
            });
        } catch (error) {
          setIsLoading(false);
          console.log("catch error", error);
          toast.error("An unexpected error occurred.");
        }
      }
    });
  };

  //show password

  const toggleOldPasswordVisibility = () => {
    setOldPasswordVisible(!oldPasswordVisible);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <>
      <DashboardHeader />
      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="white-bg my-apps-content padd20">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Old Password</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-lock" />
                          </div>
                          <input
                            type={oldPasswordVisible ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter password"
                            name="currentPassword"
                            value={currentPassword}
                            onChange={handleCurrentPassword}
                          />
                          {errors.currentPassword && (
                            <small className="form-text text-danger">
                              {errors.currentPassword}
                            </small>
                          )}
                          <div
                            className="eye-view-btn"
                            onClick={toggleOldPasswordVisibility}
                          >
                            <i
                              className={`ti ${
                                oldPasswordVisible ? "ti-eye-off" : "ti-eye"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>New Password</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-lock" />
                          </div>
                          <input
                            type={passwordVisible ? "text" : "password"}
                            className="form-control"
                            name="newPassword"
                            value={newPassword}
                            onChange={handleNewPassword}
                            placeholder="New Password"
                          />
                          {errors.newPassword && (
                            <small className="form-text text-danger">
                              {errors.newPassword}
                            </small>
                          )}

                          <div
                            className="eye-view-btn"
                            onClick={togglePasswordVisibility}
                          >
                            <i
                              className={`ti ${
                                passwordVisible ? "ti-eye-off" : "ti-eye"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Confirm New Password</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-lock" />
                          </div>
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleConfirmPassword}
                          />
                          {errors.confirmPassword && (
                            <small className="form-text text-danger">
                              {errors.confirmPassword}
                            </small>
                          )}
                          <div
                            className="eye-view-btn"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            <i
                              className={`ti ${
                                confirmPasswordVisible ? "ti-eye-off" : "ti-eye"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="save-btn">
                    <button className="btn">Change</button>
                  </div>
                </form>
                {isLoading && <Loader />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
