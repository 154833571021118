import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ValidateInputs } from "../helper/helper";
import { Loader } from "../components/Loader";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    acceptTermsConditions: false,
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    }));
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const validate = () => {
    const newErrors = {};

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    // Password validation
    if (!formData.password) {
      newErrors.password = "Password is required";
    }

    if (!formData.acceptTermsConditions) {
      newErrors.acceptTermsConditions = "Please accept terms & conditions";
    }

    setErrors(newErrors);
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      try {
        setIsLoading(true);
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/auth/merchant-login`,
            formData
          )
          .then(async (response) => {
            console.log("response.data : ", response?.data);

            if (response?.data.success) {
              if (response?.data.access_token) {
                // Direct login
                localStorage.setItem("token", response.data.access_token);
                toast.success("Login successfully!");
                navigate("/dashboard");
              } else {
                // send otp
                toast.success(response?.data.msg);
                navigate("/verify-otp", { state: { data: formData } });
              }
            } else {
              toast.error("Invalid email or password");
            }
          })
          .catch((error) => {
            console.error("error", error);
            toast.error("Invalid email or password");
          });
      } catch (error) {
        console.log("catch error", error);
        toast.error("Invalid email or password");
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrors(formErrors);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <div className="site-bg">
        <div className="inner-page-warper section-padding">
          <div className="container">
            <div className="form-box">
              <h2>Welcome Back!</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-box-inner white-bg">
                  <div className="form-group">
                    <label>Email</label>
                    <div className="form-group-inner">
                      <div className="fild-icon">
                        <i className="ti ti-mail" />
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Email"
                        name="email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="form-group-inner password-fild-box">
                      <div className="fild-icon">
                        <i className="ti ti-lock" />
                      </div>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {errors.password && (
                        <span className="error">{errors.password}</span>
                      )}
                      <div
                        className="eye-view-btn"
                        onClick={togglePasswordVisibility}
                      >
                        <i
                          className={`ti ${
                            passwordVisible ? "ti-eye-off" : "ti-eye"
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="terms-conditions-box">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="acceptTermsConditions"
                        checked={formData.acceptTermsConditions}
                        onChange={handleChange}
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Accept{" "}
                        <Link
                          to="/terms-conditions"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms &amp; Conditions
                        </Link>
                      </label>
                    </div>
                    {errors.acceptTermsConditions && (
                      <span className="error">
                        {errors.acceptTermsConditions}
                      </span>
                    )}
                  </div>
                  <div className="form-submit-btn">
                    <button className="btn">Log In</button>
                  </div>
                  <div className="forgot-btn">
                    <Link to="/forget-password">Forgot Password</Link>
                  </div>
                </div>
              </form>
              <div className="form-shape-box1">
                <img src="images/shape1.png" alt="shape" />
              </div>
              <div className="form-shape-box2">
                <img src="images/shape2.png" alt="shape" />
              </div>
              <div className="form-shape-box3">
                <img src="images/shape3.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
