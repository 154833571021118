

export const TESTNET_BINANCE_USDT_ADDRESS = "0x614BcD0853EFeE164Df71601B10904EA661C0fFD";
export const TESTNET_BINANCE_USDC_ADDRESS = "0x5A738Ced8A0e063C0e8d06Fce3D116fAF812F5b8";
export const TESTNET_BINANCE_WBNB_ADDRESS = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";
export const TESTNET_BINANCE_WBTC_ADDRESS = "";
export const TESTNET_BINANCE_WETH_ADDRESS = "";

export const getSwapContractAddresses = (chainId) => {
    switch (Number(chainId)) {
        case 11155111:
            return {
                router: "",
                factory: "",
            };
        case 80002:
            return {
                router: "",
                factory: "",
            };
        case 97:
            return {
                router: "0x9ac64cc6e4415144c455bd8e4837fea55603e5c3",
                factory: "0xB7926C0430Afb07AA7DEfDE6DA862aE0Bde767bc",
                WETH: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
            };
        case 43113:
            return {
                router: "",
                factory: "",
            };
        default:
            throw new Error("Unsupported chainId");
    }

}

export const getTokenContractAddresses = (chainId) => {
    switch (Number(chainId)) {
        case 11155111:
            return [{
                name: "Select Crypto",
                value: "",
            }]
        case 80002:
            return [{
                name: "Select Crypto",
                value: "",
            }]
        case 97:
            return [
              {
                name: "Select Crypto",
                value: "",
              },
              {
                name: "USDT",
                value: TESTNET_BINANCE_USDT_ADDRESS,
              },
              {
                name: "USDC",
                value: TESTNET_BINANCE_USDC_ADDRESS,
              },
              {
                name: "BNB",
                value: TESTNET_BINANCE_WBNB_ADDRESS,
              },
              {
                name: "ETH",
                value: TESTNET_BINANCE_WETH_ADDRESS,
              },
              {
                name: "BTC",
                value: TESTNET_BINANCE_WBTC_ADDRESS,
              }]
              
        case 43113:
            return [{
                name: "Select Crypto",
                value: "",
            }]
        default:
            throw new Error("Unsupported chainId");
    }
}


// export const getTokenContractAddresses = (chainId) => {
//     switch (Number(chainId)) {
//         case 11155111:
//             return []
//         case 80002:
//             return []
//         case 97:
//             return [{
//                 name: "USDT",
//                 value: TESTNET_BINANCE_USDT_ADDRESS,
//               },
//               {
//                 name: "USDC",
//                 value: TESTNET_BINANCE_USDC_ADDRESS,
//               },
//               {
//                 name: "BNB",
//                 value: NATIVE,
//               },
//               {
//                 name: "ETH",
//                 value: TESTNET_BINANCE_WETH_ADDRESS,
//               },
//               {
//                 name: "BTC",
//                 value: TESTNET_BINANCE_WBTC_ADDRESS,
//               }]
              
//         case 43113:
//             return []
//         default:
//             throw new Error("Unsupported chainId");
//     }

// }


