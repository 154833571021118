import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";

const OurServices = () => {
  const [title, setTitle] = useState("");
  const [serviceHeadings, setServiceHeadings] = useState([]);
  const [serviceSubHeadings, setServiceSubHeadings] = useState([]);

  const fetchPages = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/pages/slug`, {
          params: { slug: "our-services" },
        })
        .then((res) => {
          console.log("res", res);

          setTitle(res?.data?.page?.title);
          setServiceHeadings(res?.data?.page?.serviceHeading || []);
          setServiceSubHeadings(res?.data?.page?.serviceSubHeading || []);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="inner-page-head">
          <div className="container">
            <h1>{title}</h1>
            <ul>
              <li className="home">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/our-services">&rarr; Our Services</Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="our-services-section section-padding">
          <div className="container">
            {/* <div className="row">
              <div className="col-md-3">
                <div className="services-box">
                  <figure>
                    <img src="images/serv1.png" alt="service" />
                  </figure>
                  <figcaption>
                    <h3>{serviceHeading0}</h3>
                    <p>{serviceSubHeading0}</p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="services-box">
                  <figure>
                    <img src="images/serv2.png" alt="service" />
                  </figure>
                  <figcaption>
                    <h3>{serviceHeading1}</h3>
                    <p>{serviceSubHeading1}</p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="services-box">
                  <figure>
                    <img src="images/serv3.png" alt="service" />
                  </figure>
                  <figcaption>
                    <h3>{serviceHeading2}</h3>
                    <p>{serviceSubHeading2}</p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="services-box">
                  <figure>
                    <img src="images/serv4.png" alt="service" />
                  </figure>
                  <figcaption>
                    <h3>{serviceHeading3}</h3>
                    <p>{serviceSubHeading3}</p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="services-box">
                  <figure>
                    <img src="images/serv5.png" alt="service" />
                  </figure>
                  <figcaption>
                    <h3>{serviceHeading4}</h3>
                    <p>{serviceSubHeading4}</p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="services-box">
                  <figure>
                    <img src="images/serv6.png" alt="service" />
                  </figure>
                  <figcaption>
                    <h3>{serviceHeading5}</h3>
                    <p>{serviceSubHeading5}</p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="services-box">
                  <figure>
                    <img src="images/serv7.png" alt="service" />
                  </figure>
                  <figcaption>
                    <h3>{serviceHeading6}</h3>
                    <p>{serviceSubHeading6}</p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="services-box">
                  <figure>
                    <img src="images/serv8.png" alt="service" />
                  </figure>
                  <figcaption>
                    <h3>{serviceHeading7}</h3>
                    <p>{serviceSubHeading7}</p>
                  </figcaption>
                </div>
              </div>
            </div> */}
            <div className="row">
              {serviceHeadings.map((heading, index) => (
                <div className="col-md-3" key={index}>
                  <div className="services-box">
                    <figure>
                      <img src={`images/serv${index + 1}.png`} alt="service" />
                    </figure>
                    <figcaption>
                      <h3>{heading}</h3>
                      <p>{serviceSubHeadings[index]}</p>
                    </figcaption>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default OurServices;
