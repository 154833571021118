// counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const pdfDataSlice = createSlice({
  name: "pdfData",
  initialState: {
    app_id: null,
    app_name: "",
    merchant_name: "",
    merchant_id: null,
    receiver_address: "",
    sender_address: "",
    invoice_no: "",
    date: "",
    email: "",
    token_name: "",
    chainId: null,
    value: 0,
    hash: "",
    platform_fee: 0,
  },
  reducers: {
    setPdfData: (state, action) => {
      console.log("state, action : ", state, action);

      return { ...state, ...action.payload };
    },
  },
});

export const { setPdfData } = pdfDataSlice.actions;
export default pdfDataSlice.reducer;
