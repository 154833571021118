import React from "react";
import PayHeader from "../../components/auth/PayHeader";

const Pay = () => {
  return (
    <>
      <PayHeader />
      <div className="site-bg">
        <div className="inner-page-warper section-padding pay-bg">
          <div className="container">
            <div className="form-box">
              <h2>Pay with Cryptocurrency</h2>
              <div className="form-box-inner white-bg">
                <div className="form-group">
                  <label>Amount (USD)</label>
                  <div className="form-group-inner">
                    <div className="fild-icon">
                      <i className="ti ti-currency-dollar" />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Amount"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="form-group-inner password-fild-box">
                    <div className="fild-icon">
                      <i className="ti ti-mail" />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
                <div className="form-submit-btn">
                  <button className="btn">Pay Now</button>
                </div>
              </div>
              <div className="form-shape-box1">
                <img src="images/shape1.png" alt="shape" />
              </div>
              <div className="form-shape-box2">
                <img src="images/shape2.png" alt="shape" />
              </div>
              <div className="form-shape-box3">
                <img src="images/shape3.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pay;
