import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import axios from "axios";
import { toast } from "react-toastify";

const Settings = () => {
  const [user, setUser] = useState({});

  const [formData, setFormData] = useState({
    id: "",
    isMFA: "",
    isNotification: "",
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/merchants/user-profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUser(response?.data);

        setFormData({
          id: response?.data?.data?._id,
          isMFA: response?.data?.data?.isMFA,
          isNotification: response?.data?.data.isNotification,
        });
      } catch (error) {
        console.error("Error fetching adminn details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  console.log("user : ", user);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value === "true",
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/merchants/update-user-profile`,
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      toast.success("Settings updated successfully!");
    } catch (error) {
      console.error("Error updating details:", error);
    }
  };
  return (
    <>
      <DashboardHeader />

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <form onSubmit={handleFormSubmit}>
                <div className="white-bg my-apps-content padd20">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Multi-factor authentication (MFA)</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-key" />
                          </div>
                          <select
                            className="form-control"
                            name="isMFA"
                            value={formData?.isMFA}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Notification</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-bell-ringing" />
                          </div>
                          <select
                            name="isNotification"
                            className="form-control"
                            value={formData?.isNotification}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="save-btn">
                    <button className="btn">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
