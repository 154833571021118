import React, { useState, useEffect } from "react";
import Sidebar from "../../components/auth/Sidebar";
import DashboardHeader from "../../components/auth/DashboardHeader";
import axios from "axios";
import { toast } from "react-toastify";
import { ValidateInputs } from "../../helper/helper";
import { Loader } from "../../components/Loader";

const MyProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [user, setUser] = useState({});
  const [userId, setUserId] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    location: "",
    description: "",
  });
  const [errors, setErrors] = useState({});

  const fetchUserDetails = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchants/user-profile`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // setUser(response?.data);
      setUserId(response?.data?.data?._id);
      setFormData({
        name: response?.data?.data?.name,
        platformName: response?.data?.data?.platformName,
        platformCategory: response?.data?.data?.platformCategory,
        email: response?.data?.data.email,
        contactNumber: response?.data?.data?.contactNumber,
        location: response?.data?.data?.location,
        description: response?.data?.data?.description,
      });
    } catch (error) {
      console.error("Error fetching adminn details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.contactNumber) {
      newErrors.contactNumber = "Contact number is required";
    } else if (!/^[6-9]\d{9}$/.test(formData.contactNumber)) {
      newErrors.contactNumber = "Contact number is invalid";
    }

    if (!formData.location.trim()) {
      newErrors.location = "location is required";
    }

    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/merchants/update-user-profile`,
        {
          id: userId,
          ...formData,
        }
      );
      toast.success("updated successfully!");
    } catch (error) {
      console.error("Error updating admin details:", error);
      alert("Failed to update admin details.");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DashboardHeader />
      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <form onSubmit={handleFormSubmit}>
                <div className="white-bg my-apps-content padd20">
                  <div className="form-group">
                    <label>Name</label>
                    <div className="form-group-inner">
                      <div className="fild-icon">
                        <i className="ti ti-user" />
                      </div>
                      <input
                        type="text"
                        name="name"
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Name"
                        value={formData?.name}
                        onChange={handleInputChange}
                        maxLength={25}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Platform Name</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-building-arch" />
                          </div>
                          <input
                            type="text"
                            name="platformName"
                            className="form-control"
                            placeholder="Enter platformName"
                            value={formData?.platformName}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Platform category</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-category-plus" />
                          </div>
                          <input
                            type="text"
                            name="platformCategory"
                            className="form-control"
                            placeholder="Enter platformCategory"
                            value={formData?.platformCategory}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Contact Number</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-phone" />
                          </div>
                          <input
                            type="text"
                            name="contactNumber"
                            className={`form-control ${
                              errors.contactNumber ? "is-invalid" : ""
                            }`}
                            placeholder="Enter Contact Number"
                            value={formData?.contactNumber}
                            onChange={handleInputChange}
                          />
                          {errors.contactNumber && (
                            <div className="invalid-feedback">
                              {errors.contactNumber}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-mail" />
                          </div>
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            // className={`form-control ${
                            //   errors.email ? "is-invalid" : ""
                            // }`}
                            placeholder="Enter email"
                            value={formData?.email}
                            // onChange={handleInputChange}
                            disabled
                          />
                          {/* {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Location</label>
                    <div className="form-group-inner">
                      <div className="fild-icon">
                        <i className="ti ti-map-pin" />
                      </div>
                      <input
                        type="text"
                        name="location"
                        className={`form-control ${
                          errors.location ? "is-invalid" : ""
                        }`}
                        placeholder="Enter location"
                        value={formData?.location}
                        onChange={handleInputChange}
                      />
                      {errors.location && (
                        <div className="invalid-feedback">
                          {errors.location}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <div className="form-group-inner">
                      <div className="fild-icon">
                        <i className="ti ti-file-description" />
                      </div>
                      <textarea
                        name="description"
                        className={`form-control ${
                          errors.description ? "is-invalid" : ""
                        }`}
                        placeholder="Enter description"
                        value={formData?.description}
                        onChange={handleInputChange}
                        maxLength={1000}
                      />
                      {errors.description && (
                        <div className="invalid-feedback">
                          {errors.description}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="save-btn">
                    <button className="btn">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
