import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import axios from "axios";
import PaymentLinkTable from "../../components/TransactionsDataTable";
import DatePicker from "react-datepicker";
import { FaTimes } from "react-icons/fa";

const WithdrawInvoice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCrypto, setSelectedCrypto] = useState(""); // State for selected cryptocurrency
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null); // State for end date

  async function getWithdrawHistory() {
    console.log("getWithdrawHistory called ");
    try {
      setIsLoading(true);
      // Prepare query parameters
      const params = {
        pageNo: currentPage,
        limitVal: 15,
        isInvoiceOnly: true,
      };
      // Add date range filter if both startDate and endDate are selected
      if (startDate && endDate) {
        params.startDate = startDate;
        params.endDate = endDate;
      }
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant-app-tx/merchant-tx-list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: params,
        }
      );

      setTransactions(res?.data?.data);
      setCurrentPage(res?.data?.currentPage);
      setTotalPages(res?.data?.totalPages);
    } catch (err) {
      console.log("Error getting wallet history: ", err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getWithdrawHistory();
  }, [currentPage, selectedCrypto]);

  const handlePageChange = (pageNo) => {
    if (pageNo > 0 && pageNo <= totalPages) {
      setCurrentPage(pageNo);
    }
  };
  // Clear selected dates
  // const clearStartDate = () => {
  //   setStartDate(null);
  //   getWithdrawHistory(); // Fetch all data after clearing date
  // };

  return (
    <>
      <DashboardHeader />

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <p>Withdraw transactions </p>
                </div>
                <div className="dashborad-head-right">
                  {/* Date search filter  */}
                  <div className="date-range-select d-flex gap-2">
                    <div className="position-relative">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Start Date"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={new Date()}
                      />
                      {startDate && (
                        <FaTimes
                          className="clear-icon"
                          onClick={() => {
                            setStartDate(null);
                            if (endDate === null) {
                              getWithdrawHistory(); // Fetch all data after clearing date
                            }
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "#888",
                          }}
                        />
                      )}
                    </div>
                    <div className="position-relative">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => {
                          setEndDate(date);
                        }}
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="End Date"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={new Date()}
                      />
                      {endDate && (
                        <FaTimes
                          className="clear-icon"
                          onClick={() => {
                            setEndDate(null);
                            if (startDate === null) {
                              getWithdrawHistory(); // Fetch all data after clearing date
                            }
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "#888",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="view-balances-btn ml-2">
                    <a
                      className="btn"
                      onClick={() => {
                        getWithdrawHistory();
                      }}
                    >
                      Search
                    </a>
                  </div>

                  <div className="year-select-box">
                    <select
                      className="form-control"
                      value={selectedCrypto}
                      onChange={(e) => {
                        setSelectedCrypto(e.target.value);
                      }}
                    >
                      <option value="">All</option>
                      <option value="USDT">USDT</option>
                      <option value="BNB">BNB</option>
                      <option value="ETH">ETH</option>
                      <option value="AVAX">AVAX</option>
                      <option value="USDC">USDC</option>
                      <option value="MATIC">MATIC</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <PaymentLinkTable
                  transactions={transactions}
                  isReceivedAmount={true}
                  isInvoice={true}
                  isStatus={false}
                />
                <div className="pagination-box">
                  <ul>
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                      >
                        <img src="images/left-arrow.png" alt="arrow" />
                      </a>
                    </li>
                    {[...Array(totalPages).keys()].map((pageNo) => (
                      <li
                        key={pageNo + 1}
                        className={currentPage === pageNo + 1 ? "active" : ""}
                      >
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(pageNo + 1);
                          }}
                        >
                          {pageNo + 1}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                      >
                        <img src="images/right-arrow.png" alt="arrow" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawInvoice;
