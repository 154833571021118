import React from "react";
import { copyToClipboard, formatDate, trimAddress } from "../helper/helper";
import { IoCopyOutline } from "react-icons/io5";
import { getNetwork } from "../helper/evmHelper";

const TransactionsDataTable = ({
  transactions,
  isCode = false,
  isAmount = false,
  isReceivedAmount = false,
  isStatus = true,
  isInvoice = false,
}) => {
  return (
    <div className="table-box">
      <table className="table">
        <thead>
          <tr>
            <th>SN</th>
            <th>Chain Id</th>
            <th>Transaction Hash</th>
            <th>From</th>
            <th>To</th>
            {isAmount && <th>Amount</th>}
            {isReceivedAmount && <th>Received</th>}
            {isCode && <th>Code</th>}
            <th>Time Stamp</th>
            {isStatus && <th>Status</th>}
            {isInvoice && <th>Invoice</th>}
          </tr>
        </thead>
        <tbody>
          {transactions?.length > 0 ? (
            transactions.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data?.chainId}</td>
                <td>
                  {trimAddress(data?.hash, 15, 8)}
                  {data?.hash ? (
                    <button
                      onClick={() => copyToClipboard(data?.hash)}
                      style={{
                        marginLeft: "1rem",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <IoCopyOutline />
                    </button>
                  ) : (
                    "-----------------------------"
                  )}
                </td>

                <td>
                  {trimAddress(data?.fromAddress, 10, 5)}
                  {data?.fromAddress ? (
                    <button
                      onClick={() => copyToClipboard(data?.fromAddress)}
                      style={{
                        marginLeft: "1rem",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <IoCopyOutline />
                    </button>
                  ) : (
                    "------------------------"
                  )}
                </td>
                <td>
                  {trimAddress(data?.toAddress ?? data?.to, 10, 5)}
                  <button
                    onClick={() => copyToClipboard(data?.toAddress ?? data?.to)}
                    style={{
                      marginLeft: "1rem",
                      border: "none",
                      background: "transparent",
                    }}
                  >
                    <IoCopyOutline />
                  </button>
                </td>
                {isAmount && (
                  <td>
                    {data?.amount} {data?.symbol}
                  </td>
                )}
                {isReceivedAmount && (
                  <td>
                    {data?.recivedAmount ?? 0} {data?.symbol}
                  </td>
                )}

                {isCode && <td>{data?.code} </td>}

                <td>{formatDate(data?.createdAt)}</td>

                {isStatus && (
                  <td>
                    <button
                      className={`status ${
                        data?.status.toUpperCase() === "SUCCESS"
                          ? "success"
                          : "pending"
                      }`}
                      onClick={() => {
                        let explorerURL =
                          getNetwork(data?.chainId).explorerURL + data?.hash;
                        window.open(data?.linkURL ?? explorerURL);
                      }}
                    >
                      {data?.status}
                    </button>
                  </td>
                )}

                {isInvoice && (
                  <td
                    onClick={() => {
                      window.open(process.env.REACT_APP_API_URL + data?.file);
                    }}
                  >
                    {data?.invoice}
                    <i
                      className="ti ti-file-type-pdf"
                      style={{
                        fontSize: "24px",
                        marginRight: "10px",
                      }}
                    />
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginTop: "20px",
                  }}
                >
                  <p>Data not found</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionsDataTable;
