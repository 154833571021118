import axios from "axios";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const fetchUserDetails = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_API_URL}/merchants/user-profile`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return true;
    } catch (error) {
      if (error.response.data.message.toLowerCase() === "unauthorized") {
        localStorage.removeItem("token");
        toast.error("Session expired");
        navigate("/login");
      }
      console.log("Error fetching :", error.response.data.message);
      return false;
    }
  };

  if (!fetchUserDetails()) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default PrivateRoute;

// import React from "react";
// import { Navigate } from "react-router-dom";

// const PrivateRoute = ({ children }) => {
//   const token = localStorage.getItem("token");

//   if (!token) {
//     return <Navigate to="/login" replace />;
//   }

//   return children;
// };

// export default PrivateRoute;
