import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="top-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footer-des">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src="images/white-logo.png" alt="logo" />
                    </Link>
                  </div>
                  <p>
                    In the rapidly evolving world of digital finance,
                    cryptocurrency platforms play a pivotal role in enabling
                    users to buy, sell, trade, and manage various digital
                    assets. This platform offer a range of services that cater
                    to both novice investors and seasoned traders.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="quick-link-box">
                  <h3>Quick Links</h3>
                  <ul>
                    <li>
                      <Link to="/our-story">Our Story</Link>
                    </li>
                    <li>
                      <Link to="/faq">Support & Help (FAQ)</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy & Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">Terms & Conditions </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className="social-box">
                  <h3>Quick Links</h3>
                  <div className="social-btn">
                    <a>
                      <img src="images/s-icon1.png" alt="s-icon" />
                    </a>
                    <a>
                      <img src="images/s-icon2.png" alt="s-icon" />
                    </a>
                    <a>
                      <img src="images/s-icon3.png" alt="s-icon" />
                    </a>
                    <a>
                      <img src="images/s-icon4.png" alt="s-icon" />
                    </a>
                    <a>
                      <img src="images/s-icon5.png" alt="s-icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-footer">
          <div className="container">
            <p>Copyright © 2024 All Right Reserved by Coinpera</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
