import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";

const Home = () => {
  //about us
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [otherValues, setOtherValus] = useState([]);

  //testimonials
  const [testiTile, setTestiTitle] = useState("");
  const [testiSubTitle, setTestiSubTitle] = useState("");
  const [testiHeading1, setTestiHeading1] = useState("");
  const [testiHeading2, setTestiHeading2] = useState("");
  const [testiHeading3, setTestiHeading3] = useState("");
  const [testiDesc1, setTestiDesc1] = useState("");
  const [testiDesc2, setTestiDesc2] = useState("");
  const [testiDesc3, setTestiDesc3] = useState("");

  //how it works
  const [hiwTitle, setHiwTitle] = useState("");
  const [hiwSubTitle, setHiwSubTitle] = useState("");
  const [hiwHeading1, setHiwHeading1] = useState("");
  const [hiwDescription1, setHiwDescription1] = useState("");
  const [hiwHeading2, setHiwHeading2] = useState("");
  const [hiwDescription2, setHiwDescription2] = useState("");
  const [hiwHeading3, setHiwHeading3] = useState("");
  const [hiwDescription3, setHiwDescription3] = useState("");
  const [hiwHeading4, setHiwHeading4] = useState("");
  const [hiwDescription4, setHiwDescription4] = useState("");
  const sliderContainer = useRef();

  const fetchPages = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/pages/slug`, {
          params: { slug: "about-us" },
        })
        .then((res) => {
          console.log("res", res);
          setHeading(res?.data?.page?.heading);
          setDescription(res?.data?.page?.description);
          setOtherValus(res?.data?.page?.otherValues);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  const fetchTestimonialPages = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/testimonial/slug`, {
          params: { slug: "testimonials" },
        })
        .then((res) => {
          console.log("res", res);
          setTestiTitle(res?.data?.page?.title);
          setTestiSubTitle(res?.data?.page?.subTitle);
          setTestiHeading1(res?.data?.page?.heading1);
          setTestiHeading2(res?.data?.page?.heading2);
          setTestiHeading3(res?.data?.page?.heading3);

          setTestiDesc1(res?.data?.page?.description1);
          setTestiDesc2(res?.data?.page?.description2);
          setTestiDesc3(res?.data?.page?.description3);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  const fetchHowItWorkPage = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/how-it-works/slug`, {
          params: { slug: "how-it-works" },
        })
        .then((res) => {
          console.log("res", res);
          setHiwTitle(res?.data?.page?.title);
          setHiwSubTitle(res?.data?.page?.subTitle);
          setHiwHeading1(res?.data?.page?.heading1);
          setHiwDescription1(res?.data?.page?.description1);
          setHiwHeading2(res?.data?.page?.heading2);
          setHiwDescription2(res?.data?.page?.description2);
          setHiwHeading3(res?.data?.page?.heading3);
          setHiwDescription3(res?.data?.page?.description3);
          setHiwHeading4(res?.data?.page?.heading4);
          setHiwDescription4(res?.data?.page?.description4);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  useEffect(() => {
    fetchPages();
    fetchTestimonialPages();
    fetchHowItWorkPage();
  }, []);

  //slider
  useEffect(() => {
    if (sliderContainer?.current) {
      const ev = new CustomEvent("initHomeSlider");
      document.dispatchEvent(ev);
    }
  }, [sliderContainer]);

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="slider-top-section">
          <div className="container">
            <div
              className="home-slider owl-carousel owl-theme"
              ref={sliderContainer}
            >
              <div className="item">
                <div className="row">
                  <div className="col-md-6">
                    <div className="slider-des">
                      <h1>
                        The most secure <span>Crypto Coin</span> Payment
                        Platform
                      </h1>
                      <p>
                        In the rapidly evolving world of digital finance,
                        cryptocurrency platforms play a pivotal role in enabling
                        users to buy, sell, trade, and manage various digital
                        assets. This platform offer a range of services that
                        cater to both novice investors and seasoned traders.{" "}
                      </p>
                      <div className="know-more-btn">
                        <a hef="javascript:;" className="btn">
                          Know More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slider-img">
                      <img src="images/girl.png" alt="girl" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row">
                  <div className="col-md-6">
                    <div className="slider-des">
                      <h1>
                        The most secure <span>Crypto Coin</span> Payment
                        Platform
                      </h1>
                      <p>
                        In the rapidly evolving world of digital finance,
                        cryptocurrency platforms play a pivotal role in enabling
                        users to buy, sell, trade, and manage various digital
                        assets. This platform offer a range of services that
                        cater to both novice investors and seasoned traders.{" "}
                      </p>
                      <div className="know-more-btn">
                        <a hef="javascript:;" className="btn">
                          Know More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slider-img">
                      <img src="images/girl.png" alt="girl" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row">
                  <div className="col-md-6">
                    <div className="slider-des">
                      <h1>
                        The most secure <span>Crypto Coin</span> Payment
                        Platform
                      </h1>
                      <p>
                        In the rapidly evolving world of digital finance,
                        cryptocurrency platforms play a pivotal role in enabling
                        users to buy, sell, trade, and manage various digital
                        assets. This platform offer a range of services that
                        cater to both novice investors and seasoned traders.{" "}
                      </p>
                      <div className="know-more-btn">
                        <a className="btn">Know More</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slider-img">
                      <img src="images/girl.png" alt="girl" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ger-img1">
              <img src="images/growth.png" alt="ger" />
            </div>
            <div className="ger-img2">
              <img src="images/coin.png" alt="ger" />
            </div>
            <div className="ger-img3">
              <img src="images/ger1.png" alt="ger" />
            </div>
            <div className="ger-img4">
              <img src="images/ger2.png" alt="ger" />
            </div>
          </div>
        </section>

        <section className="brand-section">
          <div className="container">
            <ul>
              <li>
                <a>
                  <img src="images/logo1.png" alt="logo" />
                </a>
              </li>
              <li>
                <a>
                  <img src="images/logo2.png" alt="logo" />
                </a>
              </li>
              <li>
                <a>
                  <img src="images/logo3.png" alt="logo" />
                </a>
              </li>
              <li>
                <a>
                  <img src="images/logo4.png" alt="logo" />
                </a>
              </li>
              <li>
                <a>
                  <img src="images/logo5.png" alt="logo" />
                </a>
              </li>
              <li>
                <a>
                  <img src="images/logo6.png" alt="logo" />
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className="most-trusted-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="most-trusted-ger">
                  <img src="images/trusted-img.png" alt="img" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="most-trusted-des">
                  <h2>{heading}</h2>
                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  <ul>
                    {otherValues && otherValues.length > 0 ? (
                      otherValues.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))
                    ) : (
                      <li>No values available</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="how-we-work-section section-padding pb-0">
          <div className="container">
            <div className="section-head">
              <h2>{hiwTitle}</h2>
              <p>{hiwSubTitle}</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="how-work-box">
                  <figure>
                    <img src="images/work-icon1.png" alt="work" />
                  </figure>
                  <figcaption>
                    <h3>{hiwHeading1}</h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: hiwDescription1 }}
                    ></p>
                  </figcaption>
                </div>
                <div className="how-work-box ml-50">
                  <figure>
                    <img src="images/work-icon2.png" alt="work" />
                  </figure>
                  <figcaption>
                    <h3>{hiwHeading2}</h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: hiwDescription2 }}
                    ></p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-4">
                <div className="work-girl-img">
                  <img src="images/work-img-girl.png" alt="girl" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="how-work-box ml-50">
                  <figure>
                    <img src="images/work-icon3.png" alt="work" />
                  </figure>
                  <figcaption>
                    <h3>{hiwHeading3}</h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: hiwDescription3 }}
                    ></p>
                  </figcaption>
                </div>
                <div className="how-work-box">
                  <figure>
                    <img src="images/work-icon4.png" alt="work" />
                  </figure>
                  <figcaption>
                    <h3>{hiwHeading4}</h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: hiwDescription4 }}
                    ></p>
                  </figcaption>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonials-section section-padding">
          <div className="container">
            <div className="section-head">
              <h2>{testiTile}</h2>
              <p>{testiSubTitle}</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="testimonials-box">
                  <p dangerouslySetInnerHTML={{ __html: testiDesc1 }}></p>

                  <div className="testimonials-user-box">
                    <figure>
                      <img src="images/testi-user01.png" alt="user" />
                    </figure>
                    <figcaption>{testiHeading1} </figcaption>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="testimonials-box">
                  <p dangerouslySetInnerHTML={{ __html: testiDesc2 }}></p>

                  <div className="testimonials-user-box">
                    <figure>
                      <img src="images/testi-user02.png" alt="user" />
                    </figure>
                    <figcaption>{testiHeading2}</figcaption>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="testimonials-box">
                  <p dangerouslySetInnerHTML={{ __html: testiDesc3 }}></p>

                  <div className="testimonials-user-box">
                    <figure>
                      <img src="images/testi-user03.png" alt="user" />
                    </figure>
                    <figcaption>{testiHeading3}</figcaption>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Home;
