export const getInvoiceOptions = () => {
  const opt = {
    margin: [0.1, 0.1, 0.1, 0.1], // Minimal margins to maximize content area
    filename: "Invoice.pdf",
    image: { type: "jpeg", quality: 1.0 }, // Set image quality to 100%
    html2canvas: { scale: 4, useCORS: true }, // Increase scale for higher resolution (HD quality)
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    pagebreak: { mode: ["avoid-all", "css", "legacy"] }, // Control page breaks to avoid splitting content
  };
  return opt;
};

export const generateInvoiceNumber = () => {
  const timestamp = Date.now();
  const randomNumber = Math.floor(Math.random() * 90000) + 10000;
  const invoiceNumber = `INV-${timestamp}-${randomNumber}`;
  return invoiceNumber;
};
