import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import axios from "axios";

const AboutUs = () => {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [otherValues, setOtherValus] = useState([]);

  const [servicetitle, setServiceTitle] = useState("");
  const [servicesubTitle, setServiceSubTitle] = useState("");

  const [serviceHeadings, setServiceHeadings] = useState([]);
  const [serviceSubHeadings, setServiceSubHeadings] = useState([]);

  const fetchPages = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/pages/slug`, {
          params: { slug: "about-us" },
        })
        .then((res) => {
          console.log("res", res);
          setTitle(res?.data?.page?.title);
          setSubTitle(res?.data?.page?.subTitle);
          setHeading(res?.data?.page?.heading);
          // setDescription(stripHtmlTags(res?.data?.page?.description));
          setDescription(res?.data?.page?.description);
          setOtherValus(res?.data?.page?.otherValues);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  const fetchOurServicePage = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/pages/slug`, {
          params: { slug: "our-services" },
        })
        .then((res) => {
          console.log("res", res);
          setServiceTitle(res?.data?.page?.title);
          setServiceSubTitle(res?.data?.page?.subTitle);

          setServiceHeadings(res?.data?.page?.serviceHeading || []);
          setServiceSubHeadings(res?.data?.page?.serviceSubHeading || []);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  useEffect(() => {
    fetchPages();
    fetchOurServicePage();
  }, []);

  // console.log('serviceHeadings',serviceHeadings)
  // console.log('serviceSubHeadings',serviceSubHeadings)

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="about-top-section section-padding">
          <div className="container">
            <h2>
              <span>{title}</span> <br />
              {subTitle}
            </h2>
            <div className="about-image-box">
              <img src="images/about-img.png" alt="about" />
            </div>
          </div>
        </section>
        <section className="most-trusted-section section-padding paddTop0">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="most-trusted-ger">
                  <img src="images/trusted-img.png" alt="img" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="most-trusted-des">
                  <h2>{heading}</h2>
                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  <ul>
                    {otherValues.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our-services-section section-padding paddTop0">
          <div className="container">
            <div className="section-head">
              <h2>{servicetitle}</h2>
              <p>{servicesubTitle}</p>
            </div>
            <div className="row">
              {serviceHeadings.slice(0, 4).map((heading, index) => (
                <div className="col-md-3" key={index}>
                  <div className="services-box">
                    <figure>
                      <img src={`images/serv${index + 1}.png`} alt="service" />
                    </figure>
                    <figcaption>
                      <h3>{heading}</h3>
                      <p>{serviceSubHeadings[index]}</p>
                    </figcaption>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
