import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import './Apis.css'; // Import custom CSS

const Apis = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const fetchPages = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/other-pages/slug`, {
          params: { slug: "apis" },
        })
        .then((res) => {
          console.log("res", res);
          setTitle(res?.data?.page?.title);
          setDescription(res?.data?.page?.description);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  useEffect(() => {
    SwaggerUI({
      dom_id: '#swagger-ui',
      url: 'new.yaml',
    });
    fetchPages();
  }, []);

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="inner-page-head">
          <div className="container">
            <h1>{title}</h1>
            <ul className="breadcrumb">
              <li className="home">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/apis">&rarr; APIs</Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="api-section">
          <div className="container">
            <div className="row">
              <nav className="col-sm-3" id="sidebar">
                <ul className="nav nav-pills nav-stacked" /* data-spy="affix" data-offset-top="224" data-offset-bottom="320" */>
                  <li><a href="#section1">Introduction</a></li>
                  <li><a href="#section2">API Keys</a></li>
                  <li><a href="#section3">Playground</a></li>
                  <li><a href="#section4"> Subscription</a></li>
                </ul>
              </nav>

              <div className="col-sm-9">
                <div id="section1" className="section">
                  <h1>Introduction</h1>
                  <h4>Welcome to Coinpera, your all-in-one solution for seamless cryptocurrency payments.</h4>
                  <h5>Coinpera empowers your platform to effortlessly integrate and accept payments in a variety of cryptocurrencies, whether you prefer to support a single crypto or multiple options. Our comprehensive gateway is designed to provide a streamlined, efficient, and secure payment experience, enabling your business to thrive in the digital economy.</h5>
                </div>
                <hr />

                <div id="section2" className="section">
                  <h1>API Keys</h1>
                  <p>Get your Api keys here.</p>
                  
                  <Link to="/dashboard">
                  <span>API Keys</span >
                  </Link>
                </div>
                <hr />

                <div id="section3" className="section">
                  <h1>Playground</h1>
                  <div id="swagger-ui"></div>
                </div>
                <hr />

                <div id="section4" className="section">
                  <h1>Subscription</h1>
                  <p>Choose the plan that best suits your needs.</p>
                  <table className="subscription-table">
                    <thead>
                      <tr>
                        <th>Plan</th>
                        <th>Price</th>
                        <th>Features</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Basic</td>
                        <td>$10/month</td>
                        <td>Access to basic API endpoints</td>
                      </tr>
                      <tr>
                        <td>Pro</td>
                        <td>$30/month</td>
                        <td>Access to all API endpoints, higher rate limits</td>
                      </tr>
                      <tr>
                        <td>Enterprise</td>
                        <td>Contact us</td>
                        <td>Custom solutions, dedicated support</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Apis;
