import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ValidateInputs } from "../helper/helper";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    email: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const validate = () => {
    const newErrors = {};

    const nameRegex = /^[a-zA-Z\s]+$/;
    const nameWords = formData.name.trim().split(/\s+/);
    if (!formData.name) {
      newErrors.name = "Name is required";
    } else if (!nameRegex.test(formData.name)) {
      newErrors.name = "Name must be alphabetic and contain no numbers";
    } else if (formData.name.length < 3) {
      newErrors.name = "Name must contain at least three words";
    }

    // Contact number validation
    if (!formData.contactNumber) {
      newErrors.contactNumber = "Contact Number is required";
    } else if (!/^[6-9]\d{9}$/.test(formData.contactNumber)) {
      newErrors.contactNumber = "Contact number is invalid";
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    // Description validation
    if (!formData.description) {
      newErrors.description = "Description is required";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/contact-us/add`,
          formData
        );
        console.log("Form submitted successfully:", response.data);
        toast.success("Form submitted successfully!");

        setFormData({
          name: "",
          contactNumber: "",
          email: "",
          description: "",
        });
        setErrors("");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="inner-page-head">
          <div className="container">
            <h1>Contact Us</h1>
            <ul>
              <li className="home">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/contact-us">&rarr; Contact Us</Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="inquire-bg section-padding light-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="inquire-image-box">
                  <img src="images/contact-bg.png" alt="inquire" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="inquire-des">
                  <h3>Connect with us</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="inquire-form-box">
                      <div className="form-group">
                        <label>Name</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-user" />
                          </div>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Enter Name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <span className="error">{errors.name}</span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Contact Number</label>
                            <div className="form-group-inner">
                              <div className="fild-icon">
                                <i className="ti ti-phone" />
                              </div>
                              <input
                                type="text"
                                name="contactNumber"
                                className="form-control"
                                placeholder="Enter Contact Number"
                                value={formData.contactNumber}
                                onChange={handleChange}
                              />
                              {errors.contactNumber && (
                                <span className="error">
                                  {errors.contactNumber}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email</label>
                            <div className="form-group-inner">
                              <div className="fild-icon">
                                <i className="ti ti-mail" />
                              </div>
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Enter Email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                              {errors.email && (
                                <span className="error">{errors.email}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Description</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-info-circle" />
                          </div>
                          <textarea
                            className="form-control"
                            name="description"
                            placeholder="Enter Description"
                            maxLength={1000}
                            value={formData.description}
                            onChange={handleChange}
                          />
                          {errors.description && (
                            <span className="error">{errors.description}</span>
                          )}
                        </div>
                      </div>
                      <div className="form-submit-btn">
                        <button className="btn">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
