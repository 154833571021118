import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";

const Pricing = () => {
  const [title, setTitle] = useState("");

  const [heading1, setHeading1] = useState("");
  const [subheading1, setSubHeading1] = useState("");
  const [description1, setDescription1] = useState("");

  const [heading2, setHeading2] = useState("");
  const [subheading2, setSubHeading2] = useState("");
  const [description2, setDescription2] = useState("");

  const [heading3, setHeading3] = useState("");
  const [subheading3, setSubHeading3] = useState("");
  const [description3, setDescription3] = useState("");

  const fetchPages = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/pricing/slug`, {
          params: { slug: "pricing" },
        })
        .then((res) => {
          console.log("res", res);
          setTitle(res?.data?.page?.title);

          setHeading1(res?.data?.page?.heading1);
          setSubHeading1(res?.data?.page?.subHeading1);
          setDescription1(res?.data?.page?.description1);

          setHeading2(res?.data?.page?.heading2);
          setSubHeading2(res?.data?.page?.subHeading2);
          setDescription2(res?.data?.page?.description2);

          setHeading3(res?.data?.page?.heading3);
          setSubHeading3(res?.data?.page?.subHeading3);
          setDescription3(res?.data?.page?.description3);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  console.log("subheading1", subheading1);

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="inner-page-head">
          <div className="container">
            <h1>{title}</h1>
            <ul>
              <li className="home">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/pricing">&rarr; Pricing</Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="priceing-list section-padding light-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="price-box">
                  <div className="price-head">
                    <h5>{heading1}</h5>
                    <h3>{subheading1}</h3>
                  </div>
                  {/* <div className="price-des-list">
                    <ul>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor se incididunt ut labore et dolore
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur amet adipiscing
                        elit, sed do eiusmod tempor incididunt ut
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur amet adipiscing
                        elit, sed do eiusmod tempor incididunt ut
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </li>
                    </ul>
                  </div> */}
                  <div
                    className="price-des-list"
                    dangerouslySetInnerHTML={{ __html: description1 }}
                  />
                  <div className="buy-now-btn">
                    <a className="btn">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="price-box plan-active">
                  <div className="price-head">
                    <h5>{heading2}</h5>
                    <h3>{subheading2}</h3>
                  </div>
                  {/* <div className="price-des-list">
                    <ul>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor se incididunt ut labore et dolore
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur amet adipiscing
                        elit, sed do eiusmod tempor incididunt ut
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur amet adipiscing
                        elit, sed do eiusmod tempor incididunt ut
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </li>
                    </ul>
                  </div> */}
                  <div
                    className="price-des-list"
                    dangerouslySetInnerHTML={{ __html: description2 }}
                  />
                  <div className="buy-now-btn">
                    <a className="btn">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="price-box">
                  <div className="price-head">
                    <h5>{heading3}</h5>
                    <h3>{subheading3}</h3>
                  </div>
                  {/* <div className="price-des-list">
                    <ul>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor se incididunt ut labore et dolore
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur amet adipiscing
                        elit, sed do eiusmod tempor incididunt ut
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur amet adipiscing
                        elit, sed do eiusmod tempor incididunt ut
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </li>
                    </ul>
                  </div> */}
                  <div
                    className="price-des-list"
                    dangerouslySetInnerHTML={{ __html: description3 }}
                  />
                  <div className="buy-now-btn">
                    <a className="btn">Buy Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Pricing;
