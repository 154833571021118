import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { copyToClipboard, formatDate, trimAddress } from "../../helper/helper";
import { IoCopyOutline } from "react-icons/io5";
import moment from "moment";
import PaymentLinkTable from "../../components/TransactionsDataTable";

const Dashboard = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  //payment link transaction
  const [paymentTransactions, setPaymentTransactions] = useState([]);

  //show app list
  const [apps, setApps] = useState([]);

  //app transaction fetch
  const fetchAppTransactions = async () => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/merchant-app-tx/merchant-tx-list`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              pageNo: currentPage,
              limitVal: 10,
            },
          }
        )
        .then((resp) => {
          console.log("res", resp);
          setTransactions(resp?.data?.data);
          setCurrentPage(resp?.data?.currentPage);
          setTotalPages(resp?.data?.totalPages);
        })
        .catch((error) => {
          console.error("error in apss list", error);
        });
    } catch (error) {
      console.error("Error fetching apps:", error);
    }
  };

  //payment link transaction
  const fetchPaymentTransactions = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/payment-link/merchant-tx-list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
          },
        })
        .then((resp) => {
          console.log("res", resp);
          setPaymentTransactions(resp?.data?.data);
          setCurrentPage(resp?.data?.currentPage);
          setTotalPages(resp?.data?.totalPages);
        })
        .catch((error) => {
          console.error("Error in transactions list", error);
        });
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    fetchAppTransactions(currentPage);
    fetchPaymentTransactions();
  }, [currentPage]);

  const handlePageChange = (pageNo) => {
    if (pageNo > 0 && pageNo <= totalPages) {
      setCurrentPage(pageNo);
    }
  };

  //show app list
  const fetchApps = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/apps/get`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
          },
        })
        .then((resp) => {
          console.log("res", resp);
          setApps(resp?.data?.data);
          setCurrentPage(resp?.data?.currentPage);
          setTotalPages(resp?.data?.totalPages);
        })
        .catch((error) => {
          console.error("error in apss list", error);
        });
    } catch (error) {
      console.error("Error fetching apps:", error);
    }
  };

  useEffect(() => {
    fetchApps(currentPage);
  }, [currentPage]);

  console.log("appsapps", apps);

  return (
    <>
      <DashboardHeader />

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="statistics-payment-box">
              <div className="row">
                <div className="col-md-9">
                  <div className="white-bg statistics-content padd30">
                    <h2>Statistics</h2>
                    <div className="graph-image-box">
                      <img src="images/graph.png" alt="graph" />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashborad-payment-bg">
                    <div className="dashborad-payment-box white-bg padd30">
                      <figure>
                        <img src="images/cryptos.png" alt="cryptos" />
                      </figure>
                      <figcaption>
                        <h4>Total Cryptos</h4>
                        <p>
                          <span>400</span>
                          <i className="ti ti-currency-bitcoin" />
                        </p>
                      </figcaption>
                    </div>
                    <div className="dashborad-payment-box white-bg padd30">
                      <figure>
                        <img src="images/bitcoin.png" alt="cryptos" />
                      </figure>
                      <figcaption>
                        <h4>Bitcoin</h4>
                        <div className="cryptocurrencies-btn">
                          <Link
                            to="/dashboard-manage-curriencies"
                            className="btn"
                          >
                            Manage Cryptocurrencies
                          </Link>
                        </div>
                      </figcaption>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-apps-box mar-top-bottom30">
              <div className="white-bg my-apps-content padd20">
                <h2>My Apps</h2>

                <div className="my-apps-list">
                  <ul>
                    {apps.length > 0 ? (
                      apps.map((app, index) => (
                        <li key={index}>
                          <Link to={`/apps-detail/${app._id}`}>
                            <div className="my-app-box">
                              <div className="my-app-head">
                                <h3>{app?.name}</h3>
                                <span>
                                  {moment(app?.createdAt).format(
                                    "DD MMMM YYYY"
                                  )}
                                </span>
                              </div>
                              <div className="api-key-btn">
                                <a>
                                  <i className="ti ti-key" />
                                  <span>API Key</span>
                                </a>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            marginTop: "20px",
                          }}
                        >
                          <p>No apps found</p>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="transactions-list">
              <div className="white-bg my-apps-content padd20">
                <h2>Recent Apps Transactions</h2>
                <PaymentLinkTable
                  transactions={transactions}
                  isReceivedAmount={true}
                />
              </div>
            </div>

            <div className="transactions-list">
              <div className="white-bg my-apps-content padd20">
                <h2>Recent Payment Links</h2>
                <PaymentLinkTable
                  transactions={paymentTransactions}
                  isCode={true}
                  isAmount={true}
                  isReceivedAmount={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
