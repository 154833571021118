import React, { useState, useRef, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Loader } from "../components/Loader";

const OTPVerification = () => {
  const [timer, setTimer] = useState(60);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    otp: ["", "", "", ""],
    email: "",
  });
  const [errors, setErrors] = useState({ otp: "" });

  const location = useLocation();
  const data = location.state?.data || "";

  // console.log("data : ", data);

  const otpRefs = useRef([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!data?.email) {
      navigate("/login");
    }
  }, [data?.email]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...formData.otp];
      newOtp[index] = value;
      setFormData({ otp: newOtp });

      if (value !== "" && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && formData.otp[index] === "" && index > 0) {
      otpRefs.current[index - 1].focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      otpRefs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < otpRefs.current.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("formData : ", formData, formData.otp, typeof formData.otp);

    if (formData.otp.some((digit) => digit === "")) {
      setErrors({ otp: "Please enter a valid 4-digit OTP" });
    } else {
      // Handle OTP submission logic
      try {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/auth/merchant-otp-verify`, {
            otp: Number(formData.otp.join("")),
            email: data?.email,
          })
          .then(async (response) => {
            console.log("response.data : ", response?.data);

            if (response?.data.success) {
              setIsDisabled(true);
              setTimer(60); // 60 seconds countdown
              localStorage.setItem("token", response.data.access_token);
              toast.success("Login successfully!");
              navigate("/dashboard");
            }
          })
          .catch((error) => {
            if (error.response.data.response.error) {
              toast.error(error.response.data.response.error);
              return;
            } else {
              const msg =
                Array.isArray(error.response.data.message) &&
                error.response.data.message.length > 0
                  ? error.response.data.message[0]
                  : error.response.data.message;

              toast.error(error.response.data.message);
            }
          });
      } catch (error) {
        console.log("catch error", error);
        toast.error("Invalid email or password");
      }

      console.log("Submitted OTP:", formData.otp.join(""));
      setErrors({ otp: "" }); // Clear errors if successful
      // You can add the API call here
    }
  };

  const resendOTP = async () => {
    try {
      setIsLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}/auth/merchant-login`, data)
        .then(async (response) => {
          console.log("response.data : ", response?.data);

          if (response?.data.success) {
            toast.success("OTP resend to your email address");
            // Disable the button and start the timer
            setIsDisabled(true);
            setTimer(60); // 60 seconds countdown
          }
        })
        .catch((error) => {
          console.error("error", error);
          toast.error("Invalid email or password");
        });
    } catch (error) {
      console.log("catch error", error);
      toast.error("Invalid email or password");
    } finally {
      setIsLoading(false);
    }
  };

  // Effect hook to handle countdown
  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      // If the timer is running, decrease it every second
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      // When the timer reaches 0, re-enable the button
      setIsDisabled(false);
    }
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [timer]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <div className="site-bg">
        <div className="inner-page-warper section-padding">
          <div className="container">
            <div className="form-box">
              <h2>Verify your OTP</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-box-inner white-bg">
                  <div
                    className="form-group"
                    style={{
                      marginBottom: "40px",
                      marginTop: "20px",
                    }}
                  >
                    <div className="form-group-inner otp-input">
                      {formData.otp.map((digit, index) => (
                        <input
                          key={index}
                          ref={(el) => (otpRefs.current[index] = el)}
                          type="text"
                          placeholder="-"
                          value={digit}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          maxLength="1"
                        />
                      ))}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      {errors.otp && (
                        <span className="error">{errors.otp}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-submit-btn">
                    <button className="btn" type="submit">
                      Verify OTP
                    </button>
                  </div>
                  <div className="forgot-btn">
                    <a
                      style={{
                        cursor: isDisabled ? "not-allowed" : "pointer", // Show "not-allowed" cursor when disabled
                        color: isDisabled ? "#ccc" : "#333", // Change color when disabled
                        textDecoration: "underline",
                        pointerEvents: isDisabled ? "none" : "auto", // Disable click events when disabled
                      }}
                      onClick={!isDisabled ? resendOTP : null} // Only allow onClick if not disabled
                    >
                      {isDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
                    </a>
                  </div>
                </div>
              </form>
              <div className="form-shape-box1">
                <img src="images/shape1.png" alt="shape" />
              </div>
              <div className="form-shape-box2">
                <img src="images/shape2.png" alt="shape" />
              </div>
              <div className="form-shape-box3">
                <img src="images/shape3.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OTPVerification;
